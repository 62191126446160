import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Select,
  TextField,
} from "@mui/material";
import {
  Autocomplete
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ChromePicker } from "react-color";
import { ToggleButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { ClickAwayListener, FormControl, Typography } from "@mui/material";
import React, { memo } from "react";
import { FileUpload, FileUploadProps } from "../../shared/FileUpload";
import { apiUploadImageDocumentMaster } from "../../../services/documentMasterAPI";
import { useAppSelector } from "../../../app/hooks";
import { selectEditingDocumentTemplate } from "../editorSlice";
import { DocumentTemplate } from "../../../types/DocumentTemplate";
import { host } from "../../../services/client";
import { selectAuth } from "../../auth/authSlice";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { font_Families } from "../../../globals";

export default function HeaderFooterConfigForm(props: {
  id: number;
  title: string;
  type?: string;
  font: any;
  setFont: any;
  fontSize: any;
  setFontSize: any;
  fontStyle: any;
  setFontStyle: any;
  fontColor: any;
  setFontColor: any;
  freeText: any;
  setFreeText: any;
  selectionChoice: any;
  setSelectionChoice: any;
  pageNumberStyle: any;
  setPageNumberStyle: any;
  isPageNumberOnFirst: any;
  setIsPageNumberOnFirst: any;
  imageID: any;
  setImageID: any;
  imageFile?: any;
  setImageFile?: any;
  pageStyle?: any;
  setPageStyle?: any;
}) {
  const documentTemplate = useAppSelector(
    selectEditingDocumentTemplate
  ) as DocumentTemplate;
  const auth = useAppSelector(selectAuth);
  const [uploadSatus, setUploadStatus] = React.useState<string>("Empty");
  
  const fontFamilies = font_Families

  // const fontFamilies = [
  //   { label: "Arial", value: "arial" },
  //   { label: "AvantGarde", value: "avantgarde" },
  //   { label: "Baskerville", value: "baskerville" },
  //   { label: "Bodoni", value: "bodoni" },
  //   { label: "Bookman", value: "bookman" },
  //   { label: "Century Gothic", value: "centurygothic" },
  //   { label: "Chancery", value: "chancery" },
  //   { label: "Computer Modern", value: "computerm" },
  //   { label: "Courier", value: "courier" },
  //   { label: "Eurostile", value: "eurostile" },
  //   { label: "Futura", value: "futura" },
  //   { label: "Garamond", value: "garamond" },
  //   { label: "Georgia", value: "georgia" },
  //   { label: "Gill Sans", value: "gillsans" },
  //   { label: "Helvetica", value: "helvetica" },
  //   { label: "Hoefler Text", value: "hoefler" },
  //   { label: "Impact", value: "impact" },
  //   { label: "Latin Modern", value: "latinmodern" },
  //   { label: "Lucida", value: "lucida" },
  //   { label: "Minion", value: "minion" },
  //   { label: "Optima", value: "optima" },
  //   { label: "Palatino", value: "palatino" },
  //   { label: "Rockwell", value: "rockwell" },
  //   { label: "Souvenir", value: "souvenir" },
  //   { label: "Times", value: "timesfont" },
  //   { label: "Univers", value: "univers" },
  //   { label: "Verdana", value: "verdana" },
  //   { label: "Zapf Chancery", value: "zapfchancery" },
  //   { label: "Adobe Caslon", value: "adobecaslon" },
  //   { label: "Albertus", value: "albertus" },
  //   { label: "American Typewriter", value: "americantypewriter" },
  //   { label: "Andale Mono", value: "andalemono" },
  //   { label: "Avenir", value: "avenir" },
  //   { label: "Bembo", value: "bembo" },
  //   { label: "Berling", value: "berling" },
  //   { label: "Berlin Sans", value: "berlinsans" },
  //   { label: "Blackadder", value: "blackadder" },
  //   { label: "Bodoni MT", value: "bodonimt" },
  //   { label: "Bradley Hand", value: "bradleyhand" },
  //   { label: "Brush Script", value: "brushscript" },
  //   { label: "Calibri", value: "calibri" },
  //   { label: "Cambria", value: "cambria" },
  //   { label: "Candara", value: "candara" },
  //   { label: "Castellar", value: "castellar" },
  //   { label: "Charlemagne", value: "charlemagne" },
  //   { label: "Cochin", value: "cochin" },
  //   { label: "Colonna", value: "colonna" },
  //   { label: "Comic Sans", value: "comicsans" },
  //   { label: "Cooper", value: "cooper" },
  //   { label: "Copperplate", value: "copperplate" },
  //   { label: "Courier New", value: "couriernew" },
  //   { label: "Didot", value: "didot" },
  //   { label: "Edwardian Script", value: "edwardianscript" },
  //   { label: "Elephant", value: "elephant" },
  //   { label: "Engravers", value: "engravers" },
  //   { label: "Euphemia", value: "euphemia" },
  //   { label: "Florence", value: "florence" },
  //   { label: "Folio", value: "folio" },
  //   { label: "Franklin Gothic", value: "franklingothic" },
  //   { label: "Frutiger", value: "frutiger" },
  //   { label: "Futura PT", value: "futurapt" },
  //   { label: "Gabriola", value: "gabriola" },
  //   { label: "Garamond Premier Pro", value: "garamondpremierpro" },
  //   { label: "Geneva", value: "geneva" },
  //   { label: "Gentium", value: "gentium" },
  //   { label: "Gill Sans MT", value: "gillsansmt" },
  //   { label: "Gloucester", value: "gloucester" },
  //   { label: "Harrington", value: "harrington" },
  //   { label: "Hausa", value: "hausa" },
  //   { label: "Humanist", value: "humanist" },
  //   { label: "Imprint", value: "imprint" },
  //   { label: "ITC Benguiat", value: "itcbenguiat" },
  //   { label: "Joanna", value: "joanna" },
  //   { label: "Kaufmann", value: "kaufmann" },
  //   { label: "Kozuka Gothic", value: "kozukagothic" },
  //  { label: "Lato", value: "lato" },
  //   { label: "Lavanderia", value: "lavanderia" },
  //   { label: "Leelawadee", value: "leelawadee" },
  //   { label: "Lobster", value: "lobster" },
  //   { label: "Matisse", value: "matisse" },
  //   { label: "Mistral", value: "mistral" },
  //   { label: "Monotype Corsiva", value: "monotypecorsiva" },
  //   { label: "Myriad", value: "myriad" },
  //   { label: "Neutraface", value: "neutraface" },
  //   { label: "OCR A", value: "ocra" },
  //   { label: "Old English", value: "oldenglish" },
  //   { label: "Onyx", value: "onyx" },
  //   { label: "Optima Nova", value: "optimanova" },
  //   { label: "Orator", value: "orator" },
  //   { label: "Papyrus", value: "papyrus" },
  //   { label: "Perpetua", value: "perpetua" },
  //   { label: "Ravie", value: "ravie" },
  //   { label: "Segoe UI", value: "segoeui" },
  //   { label: "Showcard Gothic", value: "showcardgothic" },
  //   { label: "Snell Roundhand", value: "snellroundhand" },
  //   { label: "Stempel Garamond", value: "stempelgaramond" },
  //   { label: "Tahoma", value: "tahoma" },
  //   { label: "Trajan", value: "trajan" },
  //   { label: "Twentieth Century", value: "twentiethcentury" },
  //   { label: "Zurich", value: "zurich" }
  // ];

  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const [selectedColor, setSelectedColor] = React.useState<string>(
    "#" + props.fontColor
  );
  const handleColorPickerClick = () => {
    setShowColorPicker((prevState: any) => !prevState);
  };

  const handleColorPicker = (color: any) => {
    setSelectedColor(color.hex);
  };

  const handleColorPickerClose = () => {
    setShowColorPicker(false);
  };
  const handleColorPickerSave = () => {
    props.setFontColor(selectedColor.substring(1));
    setShowColorPicker(false);
  };
  const [file, setFile] = React.useState<File | null>(null);

  const handleImageUpload = async () => {
    if (file && file !== null) {
      const fileType = file.type;
    if (!fileType.startsWith("image/")) {
      // If the file is not an image, handle the error here
      console.error("Please choose an image file.");
      setUploadStatus("InvalidType")
      return;
    }
      try {
        const importResponse = await apiUploadImageDocumentMaster(
          auth.user!.default_organization_id!,
          documentTemplate,
          file
        );
        props.setImageID((await importResponse).data.document_image_id);
        setFile(file);
        setUploadStatus("Uploaded");
      } catch (error) {
        console.error("Error occurred during image upload:", error);
        setUploadStatus("Error");
        // Handle the error here, e.g., show an error message to the user
      }
    }
  };

  const [ID, setID] = React.useState<number>(props.id);
  const handleImageChange = (event: any) => {
    setUploadStatus("");
    setFile(event.target.files[0]);
    // props.setImageFile(ID, event.target.files[0]);
  };


  React.useEffect(() => {
    handleImageUpload();
  }, [file]);
  const fileUploadProp: FileUploadProps = {
    accept: "image/*",
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        // setFile(event.target.files[0]);
        props.setImageFile(ID, event.target.files[0]);
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      // setFile(event.dataTransfer.files[0]);
      props.setImageFile(event.dataTransfer.files[0]);
    },
  };
  
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          backgroundColor: "#ebeaea",
          borderRadius: "3px",
          padding: "8px",
          marginBottom: "8px",
          marginTop: "8px",
        }}
      >
        <Typography variant="h6" fontWeight="bold" sx={{ marginRight: "16px" }}>
          {props.title}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Grid container alignItems="center" spacing={2}>
          <Grid
            container
            alignItems="center"
            spacing={2}
            marginTop={0.5} // Adjust the marginTop value to reduce the space at the top
            paddingLeft={2}
          >
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="table-columns">Select</InputLabel>
                <Select
                  label={"Select"}
                  value={props.selectionChoice}
                  onChange={(e: any) => {
                    props.setSelectionChoice(e.target.value);
                  }}
                  //   displayEmpty
                  fullWidth
                  inputProps={{
                    "aria-label": "Select",
                  }}
                  variant="outlined"
                >
                  <MenuItem value="none">Empty</MenuItem>
                  <MenuItem value="image">Image</MenuItem>
                  {/* <MenuItem value="sectionHeading">Section Heading</MenuItem> */}
                  <MenuItem value="Page Number">Page Number</MenuItem>
                  <MenuItem value="freeText">Free Text</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {props.selectionChoice === "freeText" && (
                <TextField
                  fullWidth
                  margin="dense"
                  id={props.title}
                  value={props.freeText}
                  placeholder="Provide Text"
                  onChange={(e) => {
                    props.setFreeText(e.target.value);
                  }}
                />
              )}

              {props.selectionChoice === "image" && (
                // <FileUpload {...fileUploadProp} />
                <label
                  htmlFor={`file-upload-${props.id}`}
                  className="upload-icon"
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "8px 12px", // Adjusted padding
                      height: "56px", // Set the desired height
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FileUploadOutlinedIcon
                        style={{ fontSize: "40px", marginRight: "10px" }}
                      />
                      <span style={{ fontSize: "14px" }}>Choose Image</span>
                    </div>
                    <input
                      type="file"
                      id={`file-upload-${props.id}`}
                      accept="image/*"
                      onChange={(event) => handleImageChange(event)}
                      style={{ display: "none" }}
                      title="Choose an image to upload"
                      placeholder="Select an image"
                    />
                  </Box>
                </label>
              )}


            </Grid>
          </Grid>
          {props.selectionChoice === "image" && (
            <Grid item xs={12}>
              <Box padding={1.5} paddingTop={0}>
                <Button
                  fullWidth
                  onClick={handleImageUpload}
                  disabled={!file}
                  // style={{
                  // backgroundColor: props.imageID !== 0 && props.imageID ? "#2196f3" : "white",
                  // color: "#fff",
                  //   fontWeight: "bold",
                  //   borderRadius: "4px",
                  //   padding: "12px",
                  // }}
                >
                  {props.imageID !== 0 && props.imageID
                    ? "Image Uploaded"
                    : "Upload Image"}
                </Button>

                {props.imageID !== 0 &&
                  props.imageID &&
                  uploadSatus === "Uploaded" && (
                    <Alert
                      onClose={() => {
                        setUploadStatus("");
                      }}
                      severity="success"
                    >
                      Image Succesfully Uploaded!
                    </Alert>
                  )}
                {uploadSatus === "Error" && (
                  <Alert
                    onClose={() => {
                      setUploadStatus("");
                    }}
                    severity="error"
                  >
                    Unable to Upload Image!
                  </Alert>
                )}
                 {uploadSatus === "InvalidType" && (
                  <Alert
                    onClose={() => {
                      setUploadStatus("");
                    }}
                    severity="error"
                  >
                   Please choose an image file.
                  </Alert>
                )}
              </Box>
            </Grid>
          )}

          {(props.selectionChoice === "freeText" ||
            props.selectionChoice === "Page Number") && (
            <Grid item xs={6}>
              {/* <FormControl fullWidth>
                <TextField
                  label="Font"
                  value={props.font}
                  onChange={(e) => {
                    props.setFont(e.target.value);
                  }}
                  select
                  variant="outlined"
                >
                  {fontFamilies.map((font) => (
                    <MenuItem key={font.value} value={font.value}>
                      {font.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl> */}
<Autocomplete
  value={ fontFamilies.find((option) => option.value === props.font)}
  disablePortal
  options={fontFamilies}

  onChange={(event:any, newValue:any) => {
    const selectedValue = newValue ? newValue.value : null;
    props.setFont(selectedValue);
  }}
  renderInput={(params:any) => (
    <TextField {...params} label="Font" />
  )}
  disableClearable
  renderOption={(props, option) => (
    <li {...props} style={{ fontFamily: option.value }}>
      {option.label}
    </li>
  )}
/>
            </Grid>
          )}

          {/* {file !== null  &&
          // props.imageFile !== null &&
          props.selectionChoice === "image" ? (
            <div style={{ textAlign: "center" }}>
              <Box>
                <img
                  src={URL.createObjectURL(file)}
                  // src={imageUrl}
                  alt="Filtered Image"
                  style={{
                    // paddingLeft:"18px",
                    borderRadius: "5px",
                    maxWidth: "50%",
                    height: "auto",
                  }}
                />
              </Box>
            </div>
          ) : null} */}
          {props.imageID !== 0 &&
          props.imageID !== null &&
          // props.imageFile !== null &&
          props.selectionChoice === "image" ? (
            <div style={{ textAlign: "center" }}>
              <Box>
                <img
                  src={`${host}/organizations/${
                    auth.user!.default_organization_id
                  }/document_templates/${
                    documentTemplate.id
                  }/document_images_png/${props.imageID}/show.png`}
                  // src={imageUrl}
                  alt="Loading...."
                  style={{
                    borderRadius: "5px",
                    maxWidth: "50%",
                    height: "auto",
                  }}
                />
              </Box>
            </div>
          ) : null}
          {(props.selectionChoice === "freeText" ||
            props.selectionChoice === "Page Number") && (
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" sx={{ marginTop: 0 }}>
                <TextField
                  type="number"
                  value={props.fontSize}
                  label="Font Size"
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (!isNaN(value) && value <= 16) {
                      props.setFontSize(value);
                    } else {
                      props.setFontSize(16);
                    }
                  }}
                  inputProps={{
                    style: {
                      textAlign: "center",
                      padding: "16px 16px",
                      fontSize: "16px",
                    },
                    "aria-label": "Font Size",
                    step: "1",
                    min: "8",
                    max: "16",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}> 
          {props.selectionChoice === "Page Number" && (
                <FormControl fullWidth>
                  <InputLabel htmlFor="table-columns">Page Style</InputLabel>
                  <Select
                    label={"Page Style"}
                    value={props.pageStyle}
                    onChange={(e: any) => {
                      props.setPageStyle(e.target.value);
                    }}
                    //   displayEmpty
                    fullWidth
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="start">Page</InputAdornment>
                      ),
                      "aria-label": "Select",
                    }}
                    variant="outlined"
                  >
                     <MenuItem value=" ">#</MenuItem>
                    <MenuItem value="Page ">Page...</MenuItem>
                    <MenuItem value="Page No ">Page No...</MenuItem>
                    <MenuItem value="Page # ">Page # ...</MenuItem>
                    <MenuItem value="Pg # ">Pg # ..... </MenuItem>
                    <MenuItem value="Pg No ">Pg No..... </MenuItem>
                  </Select>
                </FormControl>
              )}
       
          </Grid>
          <Grid item xs={6}>
          {props.selectionChoice === "Page Number" && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="table-columns">
                  Number Style
                </InputLabel>
                <Select
                  label={"Number Style"}
                  value={props.pageNumberStyle}
                  onChange={(e: any) => {
                    props.setPageNumberStyle(e.target.value);
                  }}
                  //   displayEmpty
                  fullWidth
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="start">Page</InputAdornment>
                    ),
                    "aria-label": "Select",
                  }}
                  variant="outlined"
                >
                  <MenuItem value="arabic">...1,2,3</MenuItem>
                  <MenuItem value="alph">...a,b,c</MenuItem>
                  <MenuItem value="Alph">...A,B,C</MenuItem>
                  <MenuItem value="roman">...i,iii,iii </MenuItem>
                  <MenuItem value="Roman">...I,III,IV </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}  </Grid>
          {(props.selectionChoice === "freeText" ||
            props.selectionChoice === "Page Number") && (
            <Grid item xs={6}>
              <ToggleButtonGroup
                value={props.fontStyle}
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  newFormats: string[]
                ) => {
                  props.setFontStyle(newFormats);
                }}
                aria-label="text formatting"
              >
                <ToggleButton value="bold" aria-label="bold">
                  <FormatBoldIcon />
                </ToggleButton>
                <ToggleButton value="italic" aria-label="italic">
                  <FormatItalicIcon />
                </ToggleButton>
                <ToggleButton value="underline" aria-label="underlined">
                  <FormatUnderlinedIcon />
                </ToggleButton>
                <ToggleButton
                  value="color"
                  onClick={handleColorPickerClick}
                  aria-label="color"
                >
                  <FormatColorFillIcon style={{ color: props.fontColor }} />
                  <ArrowDropDownIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}

          {showColorPicker &&
            (props.selectionChoice === "freeText" ||
              props.selectionChoice === "Page Number") && (
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "38px",
                    left: "-25px",
                    zIndex: "1",
                  }}
                >
                  <Box
                    style={{
                      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                      borderRadius: "4px",
                      padding: "10px",
                      background: "#fff",
                    }}
                  >
                    <ChromePicker
                      color={selectedColor}
                      onChange={handleColorPicker}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                          variant="text"
                        fullWidth
                        onClick={handleColorPickerSave}
                        style={{ paddingRight: "15px" }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="text"
                        fullWidth
                        onClick={handleColorPickerClose}
                        style={{ paddingLeft: "15px" }}
                      >
                        Close
                      </Button>
                    </div>
                  </Box>
                </div>
              </div>
            )}

          {/* {props.selectionChoice === "Page Number" && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="table-columns">
                  Page Number Style
                </InputLabel>
                <Select
                  label={"Page Number Style"}
                  value={props.pageNumberStyle}
                  onChange={(e: any) => {
                    props.setPageNumberStyle(e.target.value);
                  }}
                  //   displayEmpty
                  fullWidth
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="start">Page</InputAdornment>
                    ),
                    "aria-label": "Select",
                  }}
                  variant="outlined"
                >
                  <MenuItem value="arabic">...1,2,3</MenuItem>
                  <MenuItem value="alph">...a,b,c</MenuItem>
                  <MenuItem value="Alph">...A,B,C</MenuItem>
                  <MenuItem value="roman">...i,iii,iii </MenuItem>
                  <MenuItem value="Roman">...I,III,IV </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )} */}

          {/* { props.selectionChoice === "Page Number" &&
     <Box sx={{ paddingLeft:2, mt: 2 }}>
     <Typography
       sx={{
         display: 'inline',
         marginRight: 1,
         fontWeight: 'bold',
         fontSize: '1rem',
       }}
     >
       Display Page Number on 1st Page?
     </Typography>
     <Checkbox
       checked={props.isPageNumberOnFirst}
       onChange={(event) => props.setIsPageNumberOnFirst(event.target.checked)}
       sx={{ color: 'primary.main' }}
     />
   </Box>
        } */}
        </Grid>
      </Box>
    </Box>
  );
}

// export default  memo(HeaderFooterConfigForm);
