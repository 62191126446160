import {
  ContentState,
  convertFromHTML,
  DraftInlineStyleType,
  EditorState,
  Entity,
  Modifier,
  SelectionState,
} from "draft-js";
import { inherits } from "util";
import {
  LIST_OF_FIGURES,
  LIST_OF_TABLES,
  TABLE_OF_CONTENTS,
  TABLE_OF_CONTENTS_SECTION,
  TITLE_PAGE,
} from "../../../globals";
import { useAppSelector } from "../../../app/hooks";
import {
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate,
} from "../../../types/DocumentTemplate";
import { isIncludeImpact } from "../drawers/right/Approval/ApprovalHelperFunctions/approvalHelper";
import { clearSelectedComponent, selectDocumentState } from "../editorSlice";
import { DocumentMasterState } from "../header/documentMasterSlice";

export const removeWhitespace = (str: string) => {
  // return str.replace(/\s+/g, '').replace(/[^\w\s*_.+]/gi, ''); // Remove whitespace and special characters excluding * _ . +
  return str.trimEnd().replace(/[^\w\s*_.+@'"#():,=%-/|[{}]/gi, "");
};

export const scrollToActiveSuggestion = () => {
  const activeSuggestion = document.querySelector(
    ".rdw-suggestion-option-active"
  );
  if (activeSuggestion) {
    activeSuggestion.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }
};

export const isActiveSuggestion = () => {
  const suggestionWrapper = document.querySelector(".rdw-suggestion-wrapper");
  console.log("SUGGESTION WRAPPER", suggestionWrapper);
  const wrapperElement = document.querySelector(
    ".wrapper-class-section"
  ) as HTMLElement;
  if (!wrapperElement) return;
  const contentWrapper = wrapperElement.querySelector(
    ".DraftEditor-editorContainer"
  ) as HTMLElement;
  if (!contentWrapper) return;
  const h = contentWrapper.getBoundingClientRect().height;
  if (suggestionWrapper) {
    // Only update if the height is empty
    if (!wrapperElement.style.height) {
      wrapperElement.style.height = `${h + 120}px`; // Increase height by 130px
    }
  } else {
    // Reset the height if suggestionWrapper does not exist
    wrapperElement.style.height = "";
  }
};

// export const isActiveSuggestion = (
//   //  isHeading: boolean,
//   // id: number

//   ) => {

//   // const contentWrapperId =  isHeading
//   // ? `#text-section-${id}`
//   // : `#text-subsection-${id}`

//   const suggestionWrapper = document.querySelector('.rdw-suggestion-wrapper');
//   const wrapperElement = document.querySelector('.wrapper-class-section') as HTMLElement;
//   const contentWrapper = wrapperElement.querySelector('.DraftEditor-editorContainer') as HTMLElement;
//   const h = contentWrapper.getBoundingClientRect().height;
//   if (suggestionWrapper && wrapperElement && contentWrapper) {

//     if (!wrapperElement.style.height) {
//       // Only update if the height is empty
//       wrapperElement.style.height = (h + 130) + "px"; // Increase height by 150px

//     }
//   } else if (wrapperElement) {
//     // Reset the height if suggestionWrapper does not exist
//     wrapperElement.style.height = '';
//   }
// };

export function getBoxPositionById(
  isHeading: boolean,
  id: number
): { numberLeft: number; contentLeft: number; parentLeft: number } | null {
  const contentBoxId = isHeading
    ? `text-section-${id}`
    : `text-subsection-${id}`;
  const completeBoxId = isHeading ? `p_section-${id}` : `p_subsection-${id}`;
  const numberBoxId = isHeading
    ? `number_section-${id}`
    : `number_subsection-${id}`;

  const contentBox = document.getElementById(contentBoxId);
  const numberBox = document.getElementById(numberBoxId);
  const box = document.getElementById(completeBoxId);

  if (contentBox && numberBox && box) {
    const contentBoxPosition = contentBox.getBoundingClientRect();
    const numberBoxPosition = numberBox.getBoundingClientRect();
    const boxPosition = box.getBoundingClientRect();
    return {
      numberLeft: numberBoxPosition.left,
      contentLeft: contentBoxPosition.left,
      parentLeft: boxPosition.left,
    };
  }

  return null;
}

export const isDocumentEmpty = (template: DocumentTemplate) => {
  if (!template.sections || template.sections.length === 0) {
    return {
      isDocumentEmpty: true,
      isOnlyDisabledSectionExist: false,
    };
  } else {
    let isEmpty = true;
    for (const section of template.sections) {
      if (
        section.heading !== TABLE_OF_CONTENTS &&
        section.heading !== LIST_OF_FIGURES &&
        section.heading !== TABLE_OF_CONTENTS_SECTION &&
        section.heading !== LIST_OF_TABLES
      ) {
        isEmpty = false;
      }
    }
    return {
      isDocumentEmpty: isEmpty,
      isOnlyDisabledSectionExist: !isEmpty,
    };
  }
};

export const countTableElementInDocumentTemplate = (
  props: any,
  type: "IMAGE" | "TABLE"
) => {
  let countTableElement = 0;
  const documentTemplate = props.documentTemplate as DocumentTemplate;
  let exitLoop = false; // Flag variable to track if we need to exit from all loops

  // Labeled statement for the outermost loop
  outerLoop: if (documentTemplate.sections) {
    for (const section of documentTemplate.sections) {
      if (section.subsections) {
        for (const subsection of section.subsections) {
          if (
            Array.isArray(subsection.elements) && // Check if elements is an array
            subsection.elements.length > 0 &&
            subsection.elements[0]?.content_type === type // Added optional chaining
          ) {
            if (subsection.elements[0].id === props.element?.id) {
              countTableElement++;
              exitLoop = true; // Set the flag to true if condition is met
              break outerLoop; // Break out of all loops using the labeled statement
            } else if (
              JSON.parse(subsection.elements[0].content_format)
                .isNumberFormat === true
              // && JSON.parse(subsection.elements[0].content_format).isDisplayCaption==="true"
            ) {
              countTableElement++;
            }
          }
        }
      }
    }
  }

  return countTableElement;
};

export const getFontSize = (fontSize: string) => {
  switch (fontSize) {
    case "tiny":
      return "6pt";
    case "scriptsize":
      return "7pt";
    case "footnotesize":
      return "8pt";
    case "small":
      return "9pt";
    case "normalsize":
      return "10pt";
    case "large":
      return "12pt";
    case "Large":
      return "14pt";
    case "LARGE":
      return "16pt";
    default:
      return "inherit";
  }
};

export const htmlToEditorState = (html: any) => {
  const blocksFromHTML = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(contentState);
};

export function handleDeleteSelection(props: any, actions: any, dispatch: any) {
  if (props.parentSection && props.isHeading) {
    actions.deleteSection(props.parentSection as DocumentSection);
  } else {
    actions.deleteSubsection({
      section: props.parentSection as DocumentSection,
      subSection: props.parentSubsection as DocumentSubsection,
    });
    dispatch(clearSelectedComponent());
  }
}

export function handleDetachSelection(props: any, actions: any, dispatch: any) {
  if (props.parentSection && props.isHeading) {
    actions.deleteSectionAllDocuments(props.parentSection as DocumentSection);
  } else {
    actions.deleteSubsection({
      section: props.parentSection as DocumentSection,
      subSection: props.parentSubsection as DocumentSubsection,
      currentDocOnly: true,
    });
    dispatch(clearSelectedComponent());
  }
}

export function isSubsectionVersionAvailable(
  documentTemplate: DocumentTemplate,
  subSectionID: number
) {
  const versionedElement =
    documentTemplate.element_version?.filter((element) => {
      return element.document_sub_section_id === subSectionID;
    }) || [];

  if (versionedElement.length >= 2) {
    return true;
  }

  // Return the count of matched elements
  return false;
}

export function nextFocus() {
  const editors = document.getElementsByClassName("rdw-editor-wrapper");
  // Find the index of the currently focused editor
  let currentIndex = -1;
  for (let i = 0; i < editors.length; i++) {
    if (editors[i].contains(document.activeElement)) {
      currentIndex = i;
      break;
    }
  }
  // Focus on the next editor, or the first one if at the end of the list
  const nextIndex = currentIndex + 1 < editors.length ? currentIndex + 1 : 0;
  const nextEditor = editors[nextIndex].querySelector(".rdw-editor-main");
  if (
    nextEditor instanceof HTMLDivElement ||
    nextEditor instanceof HTMLTextAreaElement
  ) {
    nextEditor.click();
  } else {
    console.log("not focusing next editor");
  }
}

// const textCount = (paramChildsOfParents: any, subSectionid: number) => {
//   let count = 1;
//   let L1 = 0;
//   for (const current of paramChildsOfParents) {
//     if (current.type === "TEXT" && current.SubsectionID !== subSectionid) {
//       if (
//         documentMasterState.documentMaster !== undefined &&
//         documentMasterState.documentMaster !== null
//       ) {
//         if (
//           current.heading_Level !== 1 &&
//           documentMasterFormat(
//             documentMasterState,
//             "TEXT",
//             current.heading_Level
//           ).format !== "None"
//         ) {
//           count++;
//         } else if (current.heading_Level === 1) {
//           L1++;
//         }
//       }
//     } else if (current.SubsectionID === subSectionid) {
//       break;
//     }
//   }
//   return count;
// };

export const findSectionNumber = (documentSection: any) => {
  let sectionCount = 1;
  let numbering = "";
  let currentSectionNumbering = "";
  const documentTemplateState = useAppSelector(selectDocumentState);
  const sections = documentTemplateState.documentTemplate?.sections;
  let shouldBreakLoop = false; // Flag variable to control the loop

  const isDisabled = isEditorDisabled(documentSection.heading);
  if (isDisabled) {
    return "";
  }
  if (sections && !isDisabled) {
    // Initialize the section count
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (
        section.id !== documentSection.id &&
        !isEditorDisabled(section.heading)
      ) {
        sectionCount++; // Increment section count
        currentSectionNumbering = `${sectionCount}`;
        numbering = currentSectionNumbering;
      } else if (section.id === documentSection.id) {
        shouldBreakLoop = true; // Set the flag to break the loop
        break;
      }
      if (shouldBreakLoop) {
        return; // Exit the forEach loop if the flag is set
      }
    }
    return sectionCount as number;
  }

  // Rest of your code...
};

export function moveSelectedSubsectionDownward(
  parentSection: DocumentSection,
  parentSubsection: DocumentSubsection,
  switchSubsectionsFunction: any
) {
  const subheadings = parentSection.subsections!.filter(
    (subsection) => subsection.elements && subsection.elements[0]
  );
  const subheadingIndex = subheadings.findIndex(
    (subSection) => subSection.id === parentSubsection?.id
  );
  if (subheadings.length !== subheadingIndex + 1) {
    switchSubsectionsFunction({
      section: parentSection,
      sourceSubsection: subheadings[subheadingIndex],
      destinationSubsection: subheadings[subheadingIndex + 1],
    });
  }
}

export function moveSelectedSubsectionUpward(
  parentSection: DocumentSection,
  parentSubsection: DocumentSubsection,
  switchSubsectionsFunction: any
) {
  const subheadings = parentSection.subsections!.filter(
    (subsection) => subsection.elements && subsection.elements[0]
  );
  const subheadingIndex = subheadings.findIndex(
    (subSection) => subSection.id === parentSubsection?.id
  );
  if (subheadingIndex > 0) {
    switchSubsectionsFunction({
      section: parentSection,
      sourceSubsection: subheadings[subheadingIndex],
      destinationSubsection: subheadings[subheadingIndex - 1],
    });
  }
}

// export function highlightVariables(editorState: any, targetWords: any) {
//   const contentState = editorState.getCurrentContent();
//   const blocks = contentState.getBlocksAsArray();
//   const rangesToColor = [];

//   for (const block of blocks) {
//     const text = block.getText();
//     for (const wordToColor of targetWords) {
//       const words = text.split(/\s+/); // Split the text into words
//       for (const word of words) {
//         if (word === wordToColor) {
//           // Check for a whole word match
//           const startIndex = text.indexOf(word);
//           const endIndex = startIndex + word.length;
//           rangesToColor.push({ blockKey: block.getKey(), start: startIndex, end: endIndex });
//         }
//       }
//     }
//   }

//   let newContentState = contentState;
//   for (const range of rangesToColor) {
//     const mentionEntityKey = Entity.create("MENTION", "IMMUTABLE", {
//       userId: 123, // You can include additional data here
//     });

//     newContentState = Modifier.applyEntity(
//       newContentState,
//       SelectionState.createEmpty(range.blockKey).merge({
//         anchorOffset: range.start,
//         focusOffset: range.end,
//       }),
//       mentionEntityKey
//     );
//   }

//   const newEditorState = EditorState.createWithContent(newContentState);
//   return newEditorState;
// }

export function rgbToHex(r: any, g: any, b: any) {
  const componentToHex = (c: any) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const hexR = componentToHex(r);
  const hexG = componentToHex(g);
  const hexB = componentToHex(b);

  return `#${hexR}${hexG}${hexB}`;
}

export function hexToRgb(hex: any) {
  // Remove the hash character if present
  hex = hex.replace(/^#/, "");
  // Parse the hex value into RGB components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  // Return the RGB values as an object
  return { r, g, b };
}

export function applyMarkdownSyntax(editorState: EditorState): EditorState {
  let newContentState = editorState.getCurrentContent();
  const blockMap = newContentState.getBlockMap();

  blockMap.forEach((contentBlock: any) => {
    const plainText = contentBlock.getText();
    const markdownRegex = /\*\*(.*?)\*\*/g; // Regular expression for detecting **text**
    let match;
    while ((match = markdownRegex.exec(plainText)) !== null) {
      const textInsideMarkdown = match[1];
      const startIndex = match.index;
      const endIndex = startIndex + match[0].length;
      // Replace the text inside markdown and preserve existing inline styles
      newContentState = Modifier.replaceText(
        newContentState,
        SelectionState.createEmpty(contentBlock.getKey()).merge({
          anchorOffset: startIndex,
          focusOffset: endIndex,
        }),
        textInsideMarkdown,
        contentBlock.getInlineStyleAt(startIndex) // Preserve existing inline styles
      );

      // Apply bold styling to the replaced text
      newContentState = Modifier.applyInlineStyle(
        newContentState,
        SelectionState.createEmpty(contentBlock.getKey()).merge({
          anchorOffset: startIndex,
          focusOffset: startIndex + textInsideMarkdown.length,
        }),
        "BOLD"
      );
    }
  });

  const newEditorState = EditorState.createWithContent(newContentState);
  return newEditorState;
}

export function removeInlineStyles(
  editorState: EditorState,
  bold: boolean,
  italic: boolean,
  underline: boolean
): EditorState {
  let newEditorState = editorState;
  // Custom logic to handle pasted text
  const contentState = newEditorState.getCurrentContent();
  const selectionState = newEditorState.getSelection();
  let newContentState = contentState;
  // Remove inline styles for bold, italic, and underline

  const stylesToRemove: string[] = [];
  if (bold) {
    stylesToRemove.push("BOLD");
  }
  if (italic) {
    stylesToRemove.push("ITALIC");
  }

  if (underline) {
    stylesToRemove.push("UNDERLINE");
  }

  // Iterate through each block in the content and remove inline styles
  contentState.getBlockMap().forEach((contentBlock: any) => {
    const blockKey = contentBlock.getKey();
    const blockLength = contentBlock.getLength();
    const newSelectionState = selectionState.merge({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: blockLength,
    });

    // Remove the specified inline styles from the entire block
    stylesToRemove.forEach((style) => {
      newContentState = Modifier.removeInlineStyle(
        newContentState,
        newSelectionState,
        style
      );
    });
  });

  // Update editorState with the modified contentState
  newEditorState = EditorState.push(
    newEditorState,
    newContentState,
    "change-inline-style"
  );

  return newEditorState;
}

export function applyMasterFormatting(
  editorState: EditorState,
  bold: boolean,
  italic: boolean,
  underline: boolean
): EditorState {
  let newEditorState = editorState;

  // Custom logic to handle pasted text
  const contentState = newEditorState.getCurrentContent();
  const selectionState = newEditorState.getSelection();

  let newContentState = contentState;
  let currentInlineStyle = newEditorState.getCurrentInlineStyle();

  // Update currentInlineStyle based on the input parameters
  if (bold) {
    currentInlineStyle = currentInlineStyle.add("BOLD");
  }
  if (italic) {
    currentInlineStyle = currentInlineStyle.add("ITALIC");
  }
  if (underline) {
    currentInlineStyle = currentInlineStyle.add("UNDERLINE");
  }

  // Iterate through each block in the content and update inline style
  // Iterate through each block in the content and update inline style
  contentState.getBlockMap().forEach((contentBlock: any) => {
    const blockKey = contentBlock.getKey();
    const blockLength = contentBlock.getLength();
    const newSelectionState = selectionState.merge({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: blockLength,
    });

    // Convert currentInlineStyle to a set of strings
    // Apply the updated inline style to the entire block
    //   newContentState = Modifier.applyInlineStyle(
    //     newContentState,
    //     newSelectionState,
    //     'BOLD'
    // );

    if (bold) {
      newContentState = Modifier.applyInlineStyle(
        newContentState,
        newSelectionState,
        "BOLD"
      );
    }
    if (italic) {
      newContentState = Modifier.applyInlineStyle(
        newContentState,
        newSelectionState,
        "ITALIC"
      );
    }
    if (underline) {
      newContentState = Modifier.applyInlineStyle(
        newContentState,
        newSelectionState,
        "UNDERLINE"
      );
    }
  });

  // Update editorState with the modified contentState
  newEditorState = EditorState.push(
    newEditorState,
    newContentState,
    "change-inline-style"
  );

  return newEditorState;
}

export function applyItalic(editorState: any): EditorState {
  let newContentState = editorState.getCurrentContent();
  const blockMap = newContentState.getBlockMap();

  blockMap.forEach((contentBlock: any) => {
    const plainText = contentBlock.getText();
    const underscoreRegex = /_(.*?)_/g;
    let match;
    while ((match = underscoreRegex.exec(plainText)) !== null) {
      const contentInsideUnderscores = match[1];
      const startIndex = match.index;
      const endIndex = startIndex + match[0].length;

      // Replace the text within underscores and preserve existing inline styles
      newContentState = Modifier.replaceText(
        newContentState,
        SelectionState.createEmpty(contentBlock.getKey()).merge({
          anchorOffset: startIndex,
          focusOffset: endIndex,
        }),
        contentInsideUnderscores,
        contentBlock.getInlineStyleAt(startIndex) // Preserve existing inline styles
      );

      // Apply italic styling to the replaced text
      newContentState = Modifier.applyInlineStyle(
        newContentState,
        SelectionState.createEmpty(contentBlock.getKey()).merge({
          anchorOffset: startIndex,
          focusOffset: startIndex + contentInsideUnderscores.length,
        }),
        "ITALIC"
      );
    }
  });

  const newEditorState = EditorState.createWithContent(newContentState);
  return newEditorState;
}

export function highlightVariables(
  editorState: EditorState,
  targetWords: string[]
) {
  try {
    const contentState = editorState.getCurrentContent();
    const newText = contentState.getPlainText();
    const rangesToColor: any[] = [];

    // Sort targetWords by length in descending order
    targetWords.sort((a, b) => b.length - a.length);

    for (const wordToColor of targetWords) {
      let startIndex = 0;
      let index = newText.indexOf(wordToColor, startIndex);

      while (index !== -1) {
        const range: any = { start: index, end: index + wordToColor.length };

        // Check for overlaps with existing ranges
        const hasOverlap = rangesToColor.some((existingRange: any) => {
          return (
            range.start < existingRange.end && range.end > existingRange.start
          );
        });

        if (!hasOverlap) {
          rangesToColor.push(range);
        }

        startIndex = index + 1; // Move startIndex to the next character
        index = newText.indexOf(wordToColor, startIndex);
      }
    }

    let newContentState = contentState;

    for (const range of rangesToColor) {
      const mentionEntityKey = Entity.create("MENTION", "IMMUTABLE", {
        userId: 123, // You can include additional data here
      });

      newContentState = Modifier.applyEntity(
        newContentState,
        SelectionState.createEmpty(contentState.getFirstBlock().getKey()).merge(
          {
            anchorOffset: range.start,
            focusOffset: range.end,
          }
        ),
        mentionEntityKey
      );
    }

    const newEditorState = EditorState.createWithContent(newContentState);
    return applyItalic(newEditorState);
  } catch (e) {
    console.log("Unable to apply variable object properties", e);
    return editorState;
  }
}

// export function highlightVariables(editorState: any, targetWords: any) {

//   try{

//   const contentState = editorState.getCurrentContent();
//   const newText = contentState.getPlainText();
//   const rangesToColor = [];

//   for (const wordToColor of targetWords) {
//     let startIndex = 0;
//     let index = newText.indexOf(wordToColor, startIndex);

//     while (index !== -1) {
//       // Check if the match is a complete word
//       if (
//         (index === 0 || !isWordCharacter(newText[index - 1])) &&
//         (index + wordToColor.length === newText.length ||
//           !isWordCharacter(newText[index + wordToColor.length]))
//       ) {
//         rangesToColor.push({ start: index, end: index + wordToColor.length });
//       }

//       startIndex = index + 1;
//       index = newText.indexOf(wordToColor, startIndex);
//     }
//   }
//   //   for (const wordToColor of targetWords) {
//   //   let startIndex = 0;
//   //   let index = newText.indexOf(wordToColor, startIndex);

//   //   while (index !== -1) {
//   //     rangesToColor.push({ start: index, end: index + wordToColor.length });
//   //     startIndex = index + wordToColor.length;
//   //     index = newText.indexOf(wordToColor, startIndex);
//   //   }
//   // }
//   let newContentState = contentState;

//   for (const range of rangesToColor) {
//     const mentionEntityKey = Entity.create("MENTION", "IMMUTABLE", {
//       userId: 123, // You can include additional data here
//     });

//     newContentState = Modifier.applyEntity(
//       newContentState,
//       SelectionState.createEmpty(contentState.getFirstBlock().getKey()).merge({
//         anchorOffset: range.start,
//         focusOffset: range.end,
//       }),
//       mentionEntityKey
//     );
//   }

//   const newEditorState = EditorState.createWithContent(newContentState);
//   return newEditorState;
// }
// catch(e){
//   console.log("unable to apply varible object properties" , e)
//   return  editorState
// }
// }

// works fne
// export function applyColorToEditorState(editorState: any, targetWords: any) {
//   let newContentState = editorState.getCurrentContent();
//   const newText = newContentState.getPlainText();
//   const rangesToColor = [];

//   // Find ranges in the text where the <span> tag occurs
//   const spanTagRegex = /<span style="color:([^"]+)">([^<]+)<\/span>/g;
//   let match;
//   while ((match = spanTagRegex.exec(newText)) !== null) {
//     console.log("match" ,match)
//     const color = match[1];
//     const wordToColor = match[2];
//     const startIndex = match.index;
//     const endIndex = startIndex + match[0].length;

//     rangesToColor.push({ start: startIndex, end: endIndex, color, wordToColor });
//   }

//   for (const range of rangesToColor) {
//     // Apply inline style to the specified range
//     newContentState = Modifier.applyInlineStyle(
//       newContentState,
//       SelectionState.createEmpty(newContentState.getFirstBlock().getKey()).merge({
//         anchorOffset: range.start,
//         focusOffset: range.end,
//       }),
//       `COLOR_RED`
//     );
//   }

//   const newEditorState = EditorState.createWithContent(newContentState);
//   return newEditorState;
// }

// 100% correct code
// export function applyColorToEditorState(editorState: any, targetWords: any) {
//   let newContentState = editorState.getCurrentContent();
//   const blockMap = newContentState.getBlockMap();
//   console.log("🚀 ~ file: HelperEditorFunctions.ts:420 ~ applyColorToEditorState ~ blockMap:", blockMap)

//   blockMap.forEach((contentBlock:any) => {
//     const plainText = contentBlock.getText();
//     const spanTagRegex = /<span style="color:([^"]+)">([^<]+)<\/span>/g;
//     let match;
//     while ((match = spanTagRegex.exec(plainText)) !== null) {
//       console.log("🚀 ~ file: HelperEditorFunctions.ts:394 ~ blockMap.forEach ~ match:", match)
//       const color = match[1];
//       const wordToColor = match[2];
//       console.log("🚀 ~ file: HelperEditorFunctions.ts:400 ~ blockMap.forEach ~ wordToColor:", wordToColor)
//       const startIndex = match.index;
//       const endIndex = startIndex + match[0].length;

//       // Replace the <span> tags with the styled text content
//       newContentState = Modifier.replaceText(
//         newContentState,
//         SelectionState.createEmpty(contentBlock.getKey()).merge({
//           anchorOffset: startIndex,
//           focusOffset: endIndex,
//         }),
//         wordToColor, // Replace the range with the text content
//       );
//       const rbgColor = hexToRgb("#"+color);
//       newContentState = Modifier.applyInlineStyle(
//         newContentState,
//         SelectionState.createEmpty(contentBlock.getKey()).merge({
//           anchorOffset: startIndex,
//           focusOffset: startIndex + wordToColor.length,
//         }),
//         `color-rgb(${rbgColor.r},${rbgColor.b},${rbgColor.g})`
//       );
//       const colorEntityKey = Entity.create("COLOR", "MUTABLE", {
//         color: color,
//       });

//       // Apply the color entity to the specified range
//       newContentState = Modifier.applyEntity(
//         newContentState,
//         SelectionState.createEmpty(contentBlock.getKey()).merge({
//           anchorOffset: startIndex,
//           focusOffset: startIndex + wordToColor.length,
//         }),
//         colorEntityKey
//       );
//       console.log("🚀 ~ file: HelperEditorFunctions.ts:466 ~ blockMap.forEach ~ newContentState:", EditorState.createWithContent(newContentState))

//     }
//   });

//   const newEditorState = EditorState.createWithContent(newContentState);
//   return newEditorState;
// }

export function applyColorToEditorState(editorState: any, targetWords: any) {
  const newContentState = editorState.getCurrentContent();
  const blockMap = newContentState.getBlockMap();
  let newEditorState = editorState;
  const spanTagRegex = /<span style="color:([^"]+?)">([^<]+?)<\/span>/g;

  let spanCount = 0;
  blockMap.forEach((contentBlock: any) => {
    const plainText = contentBlock.getText();
    let match;
    while ((match = spanTagRegex.exec(plainText)) !== null) {
      spanCount = spanCount + 1;
    }
  });
  if (spanCount != 0) {
    for (let i = 0; i < spanCount; i++) {
      newEditorState = applyColorToSpan(newEditorState);
    }
  }

  newEditorState = highlightVariables(newEditorState, targetWords);

  return newEditorState;
}

export function applyColorToSpan(editorState: any) {
  let newContentState = editorState.getCurrentContent();
  const blockMap = newContentState.getBlockMap();

  blockMap.forEach((contentBlock: any) => {
    const plainText = contentBlock.getText();
    const spanTagRegex = /<span style="color:([^"]+?)">([^<]+?)<\/span>/g;

    let match;
    if ((match = spanTagRegex.exec(plainText)) !== null) {
      const color = match[1];
      const wordToColor = match[2];
      const startIndex = match.index;
      const endIndex = startIndex + match[0].length;

      // Replace the <span> tags with the styled text content
      newContentState = Modifier.replaceText(
        newContentState,
        SelectionState.createEmpty(contentBlock.getKey()).merge({
          anchorOffset: startIndex,
          focusOffset: endIndex,
        }),
        wordToColor // Replace the range with the text content
      );
      const rbgColor = hexToRgb("#" + color);
      newContentState = Modifier.applyInlineStyle(
        newContentState,
        SelectionState.createEmpty(contentBlock.getKey()).merge({
          anchorOffset: startIndex,
          focusOffset: startIndex + wordToColor.length,
        }),
        `color-rgb(${rbgColor.r},${rbgColor.g},${rbgColor.b})`
      );

      const entityRange = SelectionState.createEmpty(
        contentBlock.getKey()
      ).merge({
        anchorOffset: startIndex,
        focusOffset: startIndex + wordToColor.length,
      });

      // Apply the color entity
      const colorEntityKey = Entity.create("COLOR", "MUTABLE", {
        color: color,
        property: `color-rgb(${rbgColor.r},${rbgColor.g},${rbgColor.b})`,
      });
      newContentState = newContentState.mergeEntityData(colorEntityKey, {
        range: entityRange,
      });
      // Update the content state with the modified entity
      newContentState = Modifier.applyEntity(
        newContentState,
        entityRange,
        colorEntityKey
      );
    }
  });

  const newEditorState = EditorState.createWithContent(newContentState);
  return newEditorState;
}

// export function applyColorToEditorState(editorState:any, targetWords:any) {
//   let newEditorState = editorState;
//   const contentState = newEditorState.getCurrentContent();

//   contentState.getBlockMap().forEach((contentBlock:any) => {
//     const plainText = contentBlock.getText();
//     const spanTagRegex = /<span style="color:([^"]+)">([^<]+)<\/span>/g;
//     let match;

//     while ((match = spanTagRegex.exec(plainText)) !== null) {
//       const color = match[1];
//       const wordToColor = match[2];
//       const startIndex = match.index;
//       const endIndex = startIndex + match[0].length;

//       // Apply color inline style
//       const colorStyle = `color-rgb(${hexToRgb("#" + color).r},${hexToRgb("#" + color).g},${hexToRgb("#" + color).b})`;
//       newEditorState = Modifier.applyInlineStyle(
//         newEditorState.getCurrentContent(),
//         SelectionState.createEmpty(contentBlock.getKey()).merge({
//           anchorOffset: startIndex,
//           focusOffset: endIndex,
//         }),
//         colorStyle
//       );

//       // Apply color entity
//       const colorEntityKey = Entity.create('COLOR', 'MUTABLE', { color });
//       newEditorState = Modifier.applyEntity(
//         newEditorState.getCurrentContent(),
//         SelectionState.createEmpty(contentBlock.getKey()).merge({
//           anchorOffset: startIndex,
//           focusOffset: endIndex,
//         }),
//         colorEntityKey
//       );
//     }
//   });

//   return newEditorState;
// }

// export function applyColorToEditorState(editorState:any, targetWords:any) {
//   let newContentState = editorState.getCurrentContent();
//   const blockMap = newContentState.getBlockMap();

//   // Keep track of content changes
//   const contentChanges:any = [];

//   blockMap.forEach((contentBlock:any) => {
//     const plainText = contentBlock.getText();
//     const spanTagRegex = /<span style="color:([^"]+)">([^<]+)<\/span>/g;
//     let match;
//     while ((match = spanTagRegex.exec(plainText)) !== null) {
//       const color = match[1];
//       const wordToColor = match[2];
//       const startIndex = match.index;
//       const endIndex = startIndex + match[0].length;

//       // Keep track of content changes
//       contentChanges.push({
//         range: {
//           start: startIndex,
//           end: endIndex,
//         },
//         text: wordToColor,
//         style: `color-rgb(${hexToRgb("#" + color).r},${hexToRgb("#" + color).b},${hexToRgb("#" + color).g})`,
//         entity: Entity.create('COLOR', 'MUTABLE', { color }),
//       });
//     }
//   });

//   console.log("🚀 ~ file: HelperEditorFunctions.ts:484 ~ applyColorToEditorState ~ contentChanges:", contentChanges)

//   // Apply content changes in a single operation
//   contentChanges.forEach((change:any) => {
//     console.log("🚀 ~ file: HelperEditorFunctions.ts:510 ~ contentChanges.forEach ~ change:", change)
//     newContentState = Modifier.replaceText(
//       newContentState,
//       SelectionState.createEmpty(change.range.start),
//       change.text,
//       undefined,
//       change.entity
//     );

//     newContentState = Modifier.applyInlineStyle(
//       newContentState,
//       SelectionState.createEmpty(change.range.start),
//       change.style
//     );
//   });

//   const newEditorState = EditorState.createWithContent(newContentState);
//   return newEditorState;
// }

export function applyColorToEditorStateEntity(
  editorState: any,
  targetWords: any
) {
  const contentState = editorState.getCurrentContent();
  const newText = contentState.getPlainText();
  const rangesToColor = [];
  // Find ranges in the text where the <span> tag occurs
  const spanTagRegex = /<span style="color: ([^"]+)">([^<]+)<\/span>/g;
  let match;
  while ((match = spanTagRegex.exec(newText)) !== null) {
    const color = match[1];
    const wordToColor = match[2];
    const startIndex = match.index;
    const endIndex = startIndex + match[0].length;

    rangesToColor.push({
      start: startIndex,
      end: endIndex,
      color,
      wordToColor,
    });
  }
  let newContentState = contentState;
  for (const range of rangesToColor) {
    // Create a unique entity key for each color
    const colorEntityKey = Entity.create("COLOR_RED", "IMMUTABLE", {
      color: range.color,
    });

    // Apply the color entity to the specified range
    newContentState = Modifier.applyEntity(
      newContentState,
      SelectionState.createEmpty(contentState.getFirstBlock().getKey()).merge({
        anchorOffset: range.start,
        focusOffset: range.end,
      }),
      colorEntityKey
    );
  }

  const newEditorState = EditorState.createWithContent(newContentState);
  return newEditorState;
}

function isWordCharacter(char: any) {
  // You may need to modify this function to match your specific definition of word characters
  // This example considers alphanumeric characters and underscores as word characters
  return /\w/.test(char);

  // also treat special character here.
  // return /[A-Za-z0-9_.,]/.test(char);
}

// export function highlightVariables(editorState: any, targetWords: any) {

//   // const tempworks = targetWords.sort((a:any, b:any) => {
//   //   // To sort in descending order, return a negative number if b should come before a
//   //   if (a < b) return 1;
//   //   if (a > b) return -1;
//   //   return 0; // If a and b are equal, leave them in their current order
//   // });
//   const contentState = editorState.getCurrentContent();
//   const newText = contentState.getPlainText();
//   const rangesToColor = [];

//   // console.log("the temp variabales",tempworks , "targetWords" , targetWords  )

//   for (const wordToColor of targetWords) {
//     let startIndex = 0;
//     let index = newText.indexOf(wordToColor, startIndex);

//     while (index !== -1) {
//       rangesToColor.push({ start: index, end: index + wordToColor.length });
//       startIndex = index + wordToColor.length;
//       index = newText.indexOf(wordToColor, startIndex);
//     }
//   }
//   let newContentState = contentState;
//   for (const range of rangesToColor) {
//     // newContentState = Modifier.applyInlineStyle(
//     //   newContentState,
//     //   SelectionState.createEmpty(contentState.getFirstBlock().getKey()).merge({
//     //       anchorOffset: range.start,
//     //       focusOffset: range.end,
//     //     }
//     //   ),
//     //   "VARIABLE_OBJECT" // Use your custom inline style name for yellow
//     // );

//     // const mentionEntityKey = contentState.createEntity("MENTION", "IMMUTABLE", {
//     //   userId: 123, // You can include additional data here
//     // });

//     const mentionEntityKey = Entity.create("MENTION", "IMMUTABLE", {
//       userId: 123, // You can include additional data here
//     });

//     newContentState = Modifier.applyEntity(
//       newContentState,
//       SelectionState.createEmpty(contentState.getFirstBlock().getKey()).merge(
//         {
//           anchorOffset: range.start,
//           focusOffset: range.end,
//         }
//       ),
//       mentionEntityKey
//     );
//   }

//   const newEditorState = EditorState.createWithContent(newContentState);
//   return newEditorState;
// }

export const customStyleMap = {
  STRIKETHROUGH: {
    textDecoration: "line-through",
    color: "red",
  },
  COLOR_RED: {
    color: "red",
  },
  COLOR_BLUE: {
    color: "blue",
  },
  CODE: {
    color: "#00C482",
    // backgroundColor: "yellow",
    // borderRadius: 1.5,
  },
  //   FONTSIZE:{
  // fontSize:28
  //   },
  d43131: {
    color: "#d43131",
  },
  MENTION: {
    color: "blue",
  },
  hashtag: {
    backgroundColor: "white !important",
  },
  mention: {
    color: "red",
  },
  custom: {
    color: "blue",
  },
  COLOR: {
    fontSize: 58,
  },
  VARIABLE_OBJECT: {
    color: "#1236ff",
    backgroundColor: "#f0fbff",
    // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Adds elevation
    borderRadius: 1.5,
    px: 3,
  },

  YELLOW_TEXT: {
    color: "yellow",
  },
};

export const applySeperatorsHeader = (format: any, text: any) => {
  let prefix = "";
  let suffix = "";
  let compound = true;
  let separator = "";
  let countType = "arabic";
  if (format.format) {
    countType = format.format;
  }
  if (format.prefix) {
    prefix = format.prefix;
  }
  if (format.suffix) {
    suffix = format.suffix;
  }
  if (format.separator) {
    separator = format.separator;
  }
  if (format.compound) {
    compound = format.compound;
  }
  // if (countType == "None") {
  //   return "";
  // }
  const parsedFormat = formatReturn(countType, text);
  return separator + prefix + parsedFormat + suffix;
};

export const applySeperatorsHeaderCompound = (format: any, text: any) => {
  let prefix = "";
  let suffix = "";
  let compound = true;
  let separator = "";
  let countType = "arabic";
  if (format.format) {
    countType = format.format;
  }
  if (format.prefix) {
    prefix = format.prefix;
  }
  if (format.suffix) {
    suffix = format.suffix;
  }
  if (format.separator) {
    separator = format.separator;
  }
  if (format.compound) {
    compound = format.compound;
  }
  // if (countType == "None") {
  //   return "";
  // }
  const parsedFormat = formatReturn(countType, text);
  return prefix + parsedFormat + suffix;
};

// Helper Function to Apply the Formatting from the Document Template Master for Numbering:
export const applySeperators = (format: any, text: any) => {
  let prefix = "";
  let suffix = "";
  let compound = true;
  let separator = "";
  let countType = "arabic";
  if (format.format) {
    countType = format.format;
  }
  if (format.prefix) {
    prefix = format.prefix;
  }
  if (format.suffix) {
    suffix = format.suffix;
  }
  if (format.separator) {
    separator = format.separator;
  }
  if (format.compound) {
    compound = format.compound;
  }
  // if (countType == "None") {
  //   return "";
  // }

  const parsedFormat = formatReturn(countType, text);
  return prefix + parsedFormat + suffix;
};

export const getUpdatedSubsectionByID = (
  documentTemplate: DocumentTemplate,
  documentSectionID: number,
  documentSubsectionID: number
) => {
  if (
    documentTemplate &&
    documentSectionID &&
    documentSubsectionID &&
    documentTemplate.sections
  ) {
    const sectionIndex = documentTemplate.sections.findIndex(
      (section) => section.id === documentSectionID
    );
    if (
      documentTemplate !== null &&
      sectionIndex !== -1 &&
      documentTemplate.sections[sectionIndex].subsections
    ) {
      const section = documentTemplate.sections[sectionIndex];
      const subsectionIndex = section.subsections!.findIndex(
        (subsection) => subsection.id === documentSubsectionID
      );
      if (subsectionIndex !== -1) {
        return section.subsections![subsectionIndex];
      }
    }
  }
  return null; // or handle the case when the subsection is not found
};

export const getUpdatedSubsection = (
  documentTemplate: DocumentTemplate,
  documentSection: DocumentSection,
  documentSubsection: DocumentSubsection
) => {
  if (
    documentTemplate &&
    documentSection &&
    documentSubsection &&
    documentTemplate.sections
  ) {
    const sectionIndex = documentTemplate.sections.findIndex(
      (section) => section.id === documentSection.id
    );
    if (
      documentTemplate !== null &&
      sectionIndex !== -1 &&
      documentTemplate.sections[sectionIndex].subsections
    ) {
      const section = documentTemplate.sections[sectionIndex];
      const subsectionIndex = section.subsections!.findIndex(
        (subsection) => subsection.id === documentSubsection.id
      );
      if (subsectionIndex !== -1) {
        return section.subsections![subsectionIndex];
      }
    }
  }
  return null; // or handle the case when the subsection is not found
};

export const getUpdatedSection = (
  documentTemplate: DocumentTemplate,
  documentSection: DocumentSection
) => {
  if (documentTemplate && documentSection && documentTemplate.sections) {
    const sectionIndex = documentTemplate.sections.findIndex(
      (section) => section.id === documentSection.id
    );
    if (sectionIndex !== -1) {
      return documentTemplate.sections[sectionIndex];
    }
  }
  return null; // or handle the case when the section is not found
};

export const getUpdatedSectionbyId = (
  documentTemplate: DocumentTemplate,
  documentSection: any
) => {
  if (documentTemplate && documentSection && documentTemplate.sections) {
    const sectionIndex = documentTemplate.sections.findIndex(
      (section) => section.id === documentSection
    );
    if (sectionIndex !== -1) {
      return documentTemplate.sections[sectionIndex];
    }
  }
  return null; // or handle the case when the section is not found
};

export const isEditorDisabled = (
  heading: string | undefined | null
): boolean => {
  if (heading == undefined || null) {
    return false;
  }
  if (
    heading == LIST_OF_FIGURES ||
    heading == LIST_OF_TABLES ||
    heading == TABLE_OF_CONTENTS_SECTION ||
    heading == TITLE_PAGE
  ) {
    return true;
  } else {
    return false;
  }
};

// export function documentMasterFormat(documentMasterState:DocumentMasterState,contentType:string,heading_level:any){
//   if (
//     documentMasterState.documentMaster !== undefined &&
//     documentMasterState.documentMaster !== null
//   ){
//     if(contentType==="SECTION"){
//       const headingFormat =
//       documentMasterState.documentMaster.heading1_formatting;
//     const params = JSON.parse(headingFormat);
//     return params
//     }else if(contentType==="TEXT"){

//         if (heading_level === 1) {
//           const paragraphFormat = JSON.parse(
//             documentMasterState.documentMaster.paragraph1_formatting
//           );
// return  paragraphFormat;

//         } else if (heading_level === 2) {
//           const paragraphFormat = JSON.parse(
//             documentMasterState.documentMaster.paragraph2_formatting
//           );
//           return  paragraphFormat;
//         } else if (heading_level === 3) {
//           const paragraphFormat = JSON.parse(
//             documentMasterState.documentMaster.paragraph3_formatting
//           );
//           return  paragraphFormat;
//         } else if (heading_level === 4) {
//           const paragraphFormat = JSON.parse(
//             documentMasterState.documentMaster.paragraph4_formatting
//           );
//           return  paragraphFormat;
//         } else if (heading_level === 5) {
//           const paragraphFormat = JSON.parse(
//             documentMasterState.documentMaster.paragraph5_formatting
//           );
//           return  paragraphFormat;

//         } else if (heading_level === 6) {
//           const paragraphFormat = JSON.parse(
//             documentMasterState.documentMaster.paragraph6_formatting
//           );
//           return  paragraphFormat;
//         }
//     }
//   }else if(contentType==="HEADING"){
//     if (heading_level === 2) {
//       const headingFormat = JSON.parse(
//         documentMasterState.documentMaster.heading2_formatting)
//       return   headingFormat;
//     } else if (heading_level === 3) {
//       const headingFormat = JSON.parse(
//         documentMasterState.documentMaster.heading3_formatting)
//       return   headingFormat;
//     } else if (heading_level === 4) {
//       const headingFormat = JSON.parse(
//         documentMasterState.documentMaster.heading4_formatting)
//       return   headingFormat;
//     } else if (heading_level === 5) {
//       const headingFormat = JSON.parse(
//         documentMasterState.documentMaster.heading5_formatting)
//       return   headingFormat;

//     } else if (heading_level === 6) {
//       const headingFormat = JSON.parse(
//         documentMasterState.documentMaster.heading6_formatting)
//       return   headingFormat;
//     }
// }
// }

// type ContentType = "SECTION" | "HEADING" | "TEXT";

export function documentMasterFormat(
  documentMasterState: DocumentMasterState,
  contentType: string,
  heading_level: any
) {
  const { documentMaster } = documentMasterState;
  // console.log("documentMasterState" , documentMasterState, "documentMaster" , documentMaster )
  const dummyString = JSON.parse(
    '{"id":1,"title":"Heading 1","topMargin":"0","bottomMargin":"12","showColorPicker":false,"fontColor":"df0b0b","font":"arial","fontSize":"16","alignment":"raggedright","fontStyle":[""],"indent":0,"format":"Roman","separator":".","prefix":"(","suffix":")","compound":true ,"linespacing" : "1"}'
  );

  try {
    let headingFormat, paragraphFormat;

    if (documentMaster) {
      switch (contentType) {
        case "SECTION":
          headingFormat = documentMaster.heading1_formatting;
          return JSON.parse(headingFormat);
        case "TEXT":
          if (heading_level >= 1 && heading_level <= 6) {
            paragraphFormat = JSON.parse(
              documentMaster[`paragraph${heading_level}_formatting`]
            );
            return paragraphFormat;
          }
          break;
        case "HEADING":
          if (heading_level >= 1 && heading_level <= 6) {
            headingFormat = JSON.parse(
              documentMaster[`heading${heading_level}_formatting`]
            );
            return headingFormat;
          }
          break;
      }
    } else {
      // dummy String for Error Handling

      return dummyString;
    }
  } catch (e) {
    console.error("Error in parsing Formatting Master", e);
    return dummyString;
  }
}
export function isOrderedListApplied(text: string | undefined | null): boolean {
  // Regex pattern to match ordered list markdown
  if (text == null || undefined) {
    return false;
  } else {
    // This regex pattern will check for ordered list markdown with numbers (e.g., 1. Item)
    // If you want to support other formats like bullets (e.g., - Item) or different numberings, you can modify the regex accordingly.
    const orderedListRegex = /^\d+\.\s+/;
    // Check if the string starts with the ordered list markdown pattern
    return orderedListRegex.test(text);
  }
}

export function isUnorderedListApplied(
  text: string | undefined | null
): boolean {
  // Regex pattern to match unordered list markdown
  if (text == null || undefined) {
    return false;
  } else {
    const unorderedListRegex = /^[-*]\s+/;
    // This regex pattern will check for unordered list markdown with bullets (e.g., - Item)
    // If you want to support other bullet formats, you can modify the regex accordingly.

    // Check if the string starts with the unordered list markdown pattern
    return unorderedListRegex.test(text);
  }
}

export function isItalicApplied(text: string | undefined | null): boolean {
  // Regex pattern to match italic markdown
  if (text == null || undefined) {
    return false;
  } else {
    const italicRegex = /_(.*?)_/;
    // Check if the string matches the italic markdown pattern
    return italicRegex.test(text);
  }
}

export function isBoldApplied(text: string | undefined | null): boolean {
  if (text == null || undefined) {
    return false;
  } else {
    // Regex pattern to match bold markdown
    const boldRegex = /\*\*.*?\*\*/;

    // Check if the string matches the bold markdown pattern
    return boldRegex.test(text);
  }
}

export function isUnderlineApplied(text: string | undefined | null): boolean {
  // Regex pattern to match underline markdown
  if (text == null || undefined) {
    return false;
  } else {
    const underlineRegex = /__(.*?)__/;

    // Check if the string matches the underline markdown pattern
    return underlineRegex.test(text);
  }
}

function convertToBase26Upper(num: number): string {
  if (num === 0) {
    return "";
  }

  const mapping: { [key: number]: string } = {
    0: "",
    1: "A",
    2: "B",
    3: "C",
    4: "D",
    5: "E",
    6: "F",
    7: "G",
    8: "H",
    9: "I",
    10: "J",
    11: "K",
    12: "L",
    13: "M",
    14: "N",
    15: "O",
    16: "P",
    17: "Q",
    18: "R",
    19: "S",
    20: "T",
    21: "U",
    22: "V",
    23: "W",
    24: "X",
    25: "Y",
  };

  const remainder = num % 26;
  const quotient = Math.floor(num / 26);

  return convertToBase26Upper(quotient) + mapping[remainder];
}

function convertToBase26Lower(num: number): string {
  if (num === 0) {
    return "0";
  }
  const mapping: { [key: string]: string } = {
    "0": "0",
    "1": "a",
    "2": "b",
    "3": "c",
    "4": "d",
    "5": "e",
    "6": "f",
    "7": "g",
    "8": "h",
    "9": "i",
    "10": "j",
    "11": "k",
    "12": "l",
    "13": "m",
    "14": "n",
    "15": "o",
    "16": "p",
    "17": "q",
    "18": "r",
    "19": "s",
    "20": "t",
    "21": "u",
    "22": "v",
    "23": "w",
    "24": "x",
    "25": "y",
    "26": "z",
  };

  const remainder = num % 26;
  const quotient = Math.floor(num / 26);

  return mapping[remainder];
}

function convertToRomanUpper(num: number): string {
  const mapping: { [key: string]: string } = {
    "0": "0",
    "1": "I",
    "2": "II",
    "3": "III",
    "4": "IV",
    "5": "V",
    "6": "VI",
    "7": "VII",
    "8": "VIII",
    "9": "IX",
    "10": "X",
    "11": "XI",
    "12": "XII",
    "13": "XIII",
    "14": "XIV",
    "15": "XV",
    "16": "XVI",
    "17": "XVII",
    "18": "XVIII",
    "19": "XIX",
    "20": "XX",
    "21": "XXI",
    "22": "XXII",
    "23": "XXIII",
    "24": "XXIV",
    "25": "XXV",
    "26": "XXVI",
    "27": "XXVII",
    "28": "XXVIII",
    "29": "XXIX",
    "30": "XXX",
    "31": "XXXI",
    "32": "XXXII",
    "33": "XXXIII",
    "34": "XXXIV",
    "35": "XXXV",
    "36": "XXXVI",
    "37": "XXXVII",
    "38": "XXXVIII",
    "39": "XXXIX",
    "40": "XL",
    "41": "XLI",
    "42": "XLII",
    "43": "XLIII",
    "44": "XLIV",
    "45": "XLV",
    "46": "XLVI",
    "47": "XLVII",
    "48": "XLVIII",
    "49": "XLIX",
    "50": "L",
  };

  return mapping[num.toString()] || "";
}

function convertToRomanLower(num: number): string {
  const mapping: { [key: string]: string } = {
    "0": "0",
    "1": "i",
    "2": "ii",
    "3": "iii",
    "4": "iv",
    "5": "v",
    "6": "vi",
    "7": "vii",
    "8": "viii",
    "9": "ix",
    "10": "x",
    "11": "xi",
    "12": "xii",
    "13": "xiii",
    "14": "xiv",
    "15": "xv",
    "16": "xvi",
    "17": "xvii",
    "18": "xviii",
    "19": "xix",
    "20": "xx",
    "21": "xxi",
    "22": "xxii",
    "23": "xxiii",
    "24": "xxiv",
    "25": "xxv",
    "26": "xxvi",
    "27": "xxvii",
    "28": "xxviii",
    "29": "xxix",
    "30": "xxx",
    "31": "xxxi",
    "32": "xxxii",
    "33": "xxxiii",
    "34": "xxxiv",
    "35": "xxxv",
    "36": "xxxvi",
    "37": "xxxvii",
    "38": "xxxviii",
    "39": "xxxix",
    "40": "xl",
    "41": "xli",
    "42": "xlii",
    "43": "xliii",
    "44": "xliv",
    "45": "xlv",
    "46": "xlvi",
    "47": "xlvii",
    "48": "xlviii",
    "49": "xlix",
    "50": "l",
  };

  return mapping[num.toString()] || "";
}

// this will parse and return the exact matching format for numbering
export const formatReturn = (format: string, texts: any) => {
  const text = texts.toString();
  if (format == "None") {
    return text;
    // return "";
  } else if (format === "arabic") {
    return text;
  } else if (format === "Alph") {
    // Convert numbers to uppercase letters (A-Z)
    return convertToBase26Upper(text);
  } else if (format === "alph") {
    // Convert numbers to Lowecase letters (a-z)

    return convertToBase26Lower(text);
  } else if (format === "roman") {
    // Convert numbers to lowercase Roman numerals (i, ii, iii, iv, v, ...)
    return convertToRomanLower(text);
  } else if (format === "Roman") {
    // Convert numbers to uppercase Roman numerals (I, II, III, IV, V, ...)

    return convertToRomanUpper(text);
  } else {
    return "";
  }
};

export function findMatchingFont(fontName: string) {
  try {
    // Your list of fonts
    const fontFamilies = [
      "Arial",
      "AvantGarde",
      "Baskerville",
      "Bodoni",
      "Bookman",
      "Century Gothic",
      "Chancery",
      "Computer Modern",
      "Courier",
      "Eurostile",
      "Futura",
      "Garamond",
      "Georgia",
      "Gill Sans",
      "Helvetica",
      "Hoefler Text",
      "Impact",
      "Latin Modern",
      "Lucida",
      "Minion",
      "Optima",
      "Palatino",
      "Rockwell",
      "Souvenir",
      "Times",
      "Univers",
      "Verdana",
      "Zapf Chancery",
      "Adobe Caslon",
      "Albertus",
      "American Typewriter",
      "Andale Mono",
      "Avenir",
      "Bembo",
      "Berling",
      "Berlin Sans",
      "Blackadder",
      "Bodoni MT",
      "Bradley Hand",
      "Brush Script",
      "Calibri",
      "Cambria",
      "Candara",
      "Castellar",
      "Charlemagne",
      "Cochin",
      "Colonna",
      "Comic Sans",
      "Cooper",
      "Copperplate",
      "Courier New",
      "Didot",
      "Edwardian Script",
      "Elephant",
      "Engravers",
      "Euphemia",
      "Florence",
      "Folio",
      "Franklin Gothic",
      "Frutiger",
      "Futura PT",
      "Gabriola",
      "Garamond Premier Pro",
      "Geneva",
      "Gentium",
      "Gill Sans MT",
      "Gloucester",
      "Harrington",
      "Hausa",
      "Humanist",
      "Imprint",
      "ITC Benguiat",
      "Joanna",
      "Kaufmann",
      "Kozuka Gothic",
      "Lato",
      "Lavanderia",
      "Leelawadee",
      "Lobster",
      "Matisse",
      "Mistral",
      "Monotype Corsiva",
      "Myriad",
      "Neutraface",
      "OCR A",
      "Old English",
      "Onyx",
      "Optima Nova",
      "Orator",
      "Papyrus",
      "Perpetua",
      "Ravie",
      "Segoe UI",
      "Showcard Gothic",
      "Snell Roundhand",
      "Stempel Garamond",
      "Tahoma",
      "Trajan",
      "Twentieth Century",
      "Zurich",
    ];

    const formattedFontName = fontName.toLowerCase().replace(/\s/g, "");

    // Find the closest matching font by comparing the formatted names
    const matchingFont = fontFamilies.find(
      (font) => font.toLowerCase().replace(/\s/g, "") == formattedFontName
    );

    return matchingFont || "default"; // Return the matching font or null if not found
  } catch (e) {
    return "arial";
  }
}

// const subsectionId =  props.isHeading
// ? `section-${props.parentSection.id}`
// : `subsection-${props.parentSubsection!.id}`
// // const searchStringArray = [ "Section","abc", "rehman", "haris", "text", "dummyof", "text", "printing", 'Section', 'abc', "abc" , "Section"];
// const searchStringArrays:any = ["Section","abc", "rehman", "haris", "text", "dummyof", "text", "printing", 'Section', 'abc', "abc" , "Section"];

// if (props.documentTemplate && (props.documentTemplate as DocumentTemplate).variables) {
//   // Assuming props.documentTemplate is of type DocumentTemplate
//   (props.documentTemplate as DocumentTemplate).variables?.map((variable) => {
//     searchStringArrays.push(variable.variable_name);
//   });
// }

// const color = 'blue';

// function wrapWordsWithColor(subsectionId:any, wordArray:any, color:any) {

//   console.log("string" ,wordArray )
//   const subsection = document.getElementById(subsectionId);

//   if (!subsection) {
//     console.error(`Subsection not found: ${subsectionId}`);
//     return;
//   }

//   const spans = subsection.querySelectorAll(' span[data-text="true"]');
//   spans.forEach((span:any) => {
//     const spanText = span.innerText;
//     // Create a new HTML string with words wrapped in span elements
//     const updatedHtml = spanText
//       .split(/\s+/) // Split by whitespace
//       .map((word:any) => {
//         if (wordArray.includes(word.toLowerCase())) {
//           return `<span style="color: ${color};">${word}</span>`;
//         }
//         return word;
//       })
//       .join(' ');

//     // Update the span's content with the modified HTML
//     span.innerHTML = updatedHtml;
//   });
// }

// // Call the function to apply color to words in the specified subsection
// wrapWordsWithColor(subsectionId,  searchStringArrays, color);
// React.useEffect(()=>{
// wrapWordsWithColor(subsectionId, searchStringArrays, color);
// // wrapWordsWithColor(containerSelector, searchStringArray, color);
// ///
// },[props])

// function highlightStringDifferences(parentElementId:any, string1:any, string2:any) {
//   const parentElement = document.getElementById(parentElementId);

//   if (!parentElement) {
//     console.error(`Parent element not found: ${parentElementId}`);
//     return;
//   }

//   // Split both strings into arrays of words
//   const words1 = string1.split(/\s+/);
//   const words2 = string2.split(/\s+/);

//   // Clear the content of the parent element
//   parentElement.innerHTML = '';

//   // Iterate through the words and apply styling based on differences
//   words1.forEach((word:any, index:any) => {
//     const span = document.createElement('span');
//     if (words2[index] === undefined) {
//       // If there's no corresponding word in words2, mark it as added
//       span.style.color = 'blue';
//       span.textContent = word;
//     } else if (word !== words2[index]) {
//       // If the words are different, mark it as changed
//       span.style.color = 'red';
//       span.style.textDecoration = 'line-through';
//       span.textContent = words2[index]; // Show the old word in strikethrough
//       parentElement.appendChild(span.cloneNode(true)); // Add a copy to show the new word
//       return; // Skip appending the current span after adding a copy for the new word
//     } else {
//       // If the words are the same, mark it as common
//       span.style.color = 'black';
//       span.textContent = word;
//     }

//     parentElement.appendChild(span);
//   });
// }
// // Example usage:
// const string1 = 'This is the updated version of the text and this is dummy string';
// const string2 = 'This is the older version of the text';
// const parentElementId = props.isHeading
// ? `section-${props.parentSection.id}`
// : `subsection-${props.parentSubsection!.id}`
// highlightStringDifferences(parentElementId, string1, string2);

// // Define custom entity type
// const customEntity = {
//   CUSTOM_TAG: 'CUSTOM_TAG',
// };

// const customInlineStyle = {
//   textDecoration: {
//     STRIKETHROUGH:'custom',
//     // Add other custom styles here if needed
//   },
// };

// function parseMarkdownToEditorState(markdownContent:string) {
//   const contentState = ContentState.createFromText(markdownContent);

//   // Example: Identify and apply strikethrough style to "~~" tags
//   const text = markdownContent;
//   const blocks = contentState.getBlockMap();

//   let newContentState = contentState;

//   blocks.forEach((block) => {
//     let blockText = block!.getText();
//     const start = 0;
//     let match;

//     // Find and apply strikethrough style
//     // const regex = /==(.*?)==/g;
//     const regex = /==([^=]+)==/g;

//     while ((match = regex.exec(blockText)) !== null) {
//       const [fullMatch, content] = match;
//       const startIndex = match.index;
//       const endIndex = startIndex + fullMatch.length;

//       // Define the selection range
//       const selection = SelectionState.createEmpty(block!.getKey()).merge({
//         anchorOffset: startIndex,
//         focusOffset: endIndex,
//       });

//       // Apply the STRIKETHROUGH inline style to the specified range
//       newContentState = Modifier.applyInlineStyle(newContentState, selection, customInlineStyle.textDecoration.STRIKETHROUGH);

//       // Update the blockText to remove the strikethrough tags
//       blockText = blockText.substring(0, startIndex) + content + blockText.substring(endIndex);
//     }
//   });

//   return EditorState.createWithContent(newContentState);
// }

// const markdownContent = "This is a ==deleted== word.";

// const newcontent ="~~**Section Heading**~~ ==**SectioNOmia**=="
// const editorState = parseMarkdownToEditorState(newcontent);

// setEditorStates(
//   editorState
// );

// const customInlineStyle = {
//   textDecoration: {
//     STRIKETHROUGH:'custom',
//     // Add other custom styles here if needed
//   },
// };

// Define custom entity type

// function parseMarkdownToEditorState(markdownContent:string) {
//   const contentState = ContentState.createFromText(markdownContent);

//   // Example: Identify and apply strikethrough style to "~~" tags
//   const text = markdownContent;
//   const blocks = contentState.getBlockMap();

//   let newContentState = contentState;

//   blocks.forEach((block) => {
//     let blockText = block!.getText();
//     const start = 0;
//     let match;

//     // Find and apply strikethrough style
//     // const regex = /==(.*?)==/g;
//     const regex = /==([^=]+)==/g;

//     while ((match = regex.exec(blockText)) !== null) {
//       const [fullMatch, content] = match;
//       const startIndex = match.index;
//       const endIndex = startIndex + fullMatch.length;

//       // Define the selection range
//       const selection = SelectionState.createEmpty(block!.getKey()).merge({
//         anchorOffset: startIndex,
//         focusOffset: endIndex,
//       });

//       // Apply the STRIKETHROUGH inline style to the specified range
//       newContentState = Modifier.applyInlineStyle(newContentState, selection, customInlineStyle.textDecoration.STRIKETHROUGH);

//       // Update the blockText to remove the strikethrough tags
//       blockText = blockText.substring(0, startIndex) + content + blockText.substring(endIndex);
//     }
//   });

//   return EditorState.createWithContent(newContentState);
// }

// "# " + props.parentSection.pending_heading
//           : "# " + " Use the `code` element to display code inline."
//           : normalizeText(" ## Use the `code` element to display code inline.")
// const customStyleMap = {
//   STRIKETHROUGH: {
//     textDecoration: 'line-through',
//     color: 'red',
//   },
//   // if
//   CODE: {
//     color: '#00C482',
//   },
//   MENTION:{
//     color:'blue'
//   },
//   hashtag:{
//     color:'blue'
//   },
//   mention:{
//     color:'blue'
//   },
//   redBackground: {
//     backgroundColor: 'red',
//   },
//   greenBackground: {
//     backgroundColor: 'green',
//   },
//   custom:{
//     color:"green"
//   }

// };

// function highlightVariables(editorState:any, targetWords:any) {
//   const contentState = editorState.getCurrentContent();
//   const newText = contentState.getPlainText();
//   const rangesToColor = [];

//   for (const wordToColor of targetWords) {
//     let startIndex = 0;
//     let index = newText.indexOf(wordToColor, startIndex);

//     while (index !== -1) {
//       rangesToColor.push({ start: index, end: index + wordToColor.length });
//       startIndex = index + wordToColor.length;
//       index = newText.indexOf(wordToColor, startIndex);
//     }
//   }

//   let newContentState = contentState;

//   for (const range of rangesToColor) {
//     newContentState = Modifier.applyInlineStyle(
//       newContentState,
//       SelectionState.createEmpty(contentState.getFirstBlock().getKey())
//         .merge({
//           anchorOffset: range.start,
//           focusOffset: range.end,
//         }),
//       'VARIABLE_OBJECT' // Use your custom inline style name for yellow
//     );
//   }

//   const newEditorState = EditorState.createWithContent(newContentState);
//   return newEditorState;
// }

// const handleIncreaseIndent = () => {
//   if (!props.isHeading) {
//     const updatedElement = { ...props.element! };
//     const contentFormatObject = JSON.parse(updatedElement.content_format);
//     contentFormatObject.indent = (contentFormatObject.indent as number) + 2;
//     updatedElement.content_format = JSON.stringify(contentFormatObject);
//     updatedElement.formatting = JSON.stringify({
//       ...JSON.parse(
//         props.element?.formatting !== null &&
//           props.element?.formatting !== undefined
//           ? props.element?.formatting
//           : "{}"
//       ),
//       indent: contentFormatObject.indent as number,
//     });
//     actions.updateDocumentElement({
//       section: props.parentSection! as DocumentSection,
//       subSection: props.parentSubsection!,
//       documentElement: updatedElement as DocumentElement,
//     });
//   }
// };

// // Increse Indentation
// const handleDecreaseIndent = () => {
//   if (!props.isHeading) {
//     const updatedElement = { ...props.element! };
//     const contentFormatObject = JSON.parse(updatedElement.content_format);
//     contentFormatObject.indent = (contentFormatObject.indent as number) - 2;
//     updatedElement.formatting = JSON.stringify({
//       ...JSON.parse(
//         props.element?.formatting !== null &&
//           props.element?.formatting !== undefined
//           ? props.element?.formatting
//           : "{}"
//       ),
//       indent: contentFormatObject.indent as number,
//     });
//     if (contentFormatObject.indent >= 0) {
//       updatedElement.content_format = JSON.stringify(contentFormatObject);
//       actions.updateDocumentElement({
//         section: props.parentSection! as DocumentSection,
//         subSection: props.parentSubsection!,
//         documentElement: updatedElement as DocumentElement,
//       });
//     }
//   }
// };
