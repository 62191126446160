import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FileUpload, FileUploadProps } from "../../../shared/FileUpload";
import useActions from "../../../../app/hooks";

import {
  Alert,
  Autocomplete,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  SelectChangeEvent,
  Tab,
  Tabs,
} from "@mui/material";
import {
  DocumentElement,
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate,
} from "../../../../types/DocumentTemplate";
import { Typography } from "@mui/material";

import { getTableArray } from "./TableElementEditor";
import {
  convertMmToPt,
  formatTableWithTheme,
  updateTableThemeFormatting,
} from "./HelperFuctions/TableUtils";
import { useState } from "react";
import TableElementTheme from "./ThemeTemplate/TableElementTheme";
import ThemeDialog from "./ThemeTemplate/ThemeDialog";
import { getDefaultThemeDetails } from "./HelperFuctions/TableUtils";
import CustomTableDialog, {
  ColorPicker,
} from "./ThemeFomattingSetting/CustomThemeConfig";
import {
  DEFAULT_THEME_FORMATTING,
  font_Families,
  TABLE_THEMES,
} from "../../../../globals";
import { findMatchingFont, getFontSize } from "../HelperEditorFunctions";
import { createUpdateTable } from "./HelperFuctions/TableElementHelperFunctions";
import { setSelectedComponent } from "../../editorSlice";
import { SpaceBar } from "@mui/icons-material";

/**
 * REF: https://codesandbox.io/s/lgqwn?file=/src/FileUpload/FileUpload.tsx:1869-1877
 *
 * @export
 * @param {{open: boolean}} props
 * @return {*}
 */
export default function TableElementForm(props: {
  closeDialogHandler: any;
  setColumns: any;
  setRows: any;
  section: DocumentSection;
  subSection: DocumentSubsection;
  element: DocumentElement | any;
  originalTable: Array<Array<string>>;
  setOriginalTable: any;
  documentTemplate: DocumentTemplate;
  currentThemeDetails?: any;
  updateCurrentThemeDetails?: any;
  parentSection: any;
  parentSubsection: any;
  setFromSettings: any;
  fromSettings: any;
  setIsEditConfigOpen: any;
  setIsUpdateContent: any;
  fromThemes: any;
  sendData?: (Data: any) => void;
  colType: any;
  rowType: any;
  rowSize: any;
  _columns: any;
  _rows: any;
}) {
  const actions = useActions();
  const [updatedDetails, setUpdatedDetails] = useState({
    ...props.currentThemeDetails,
  });

  const [themesDialogOpen, setThemesDialogOpen] = React.useState(false);

  const startElement = React.useMemo(() => {
    return JSON.parse(props.element.content_format);
  }, []);
  const [columns, setColumns] = React.useState<number>(props._columns);
  const [rows, setRows] = React.useState<number>(props._rows);

  const [captionIndent, setCaptionIndent] = React.useState<number>(
    startElement.captionIndent ? startElement.captionIndent : 0
  );

  const [updateTheme, setUpdateTheme] = React.useState<boolean>(false);

  const [justify, setJustify] = React.useState<string>(
    startElement.justify ? startElement.justify : "center"
  );
  const [justifyContent, setJustifyContent] = React.useState<string>(
    startElement.justifyContent ? startElement.justifyContent : "left"
  );
  const [caption, setCaption] = React.useState<string>(
    startElement.caption ? startElement.caption : "Table"
  );
  const [captionAlignment, setCaptionAlignment] = React.useState<string>(
    startElement.captionAlignment ? startElement.captionAlignment : "center"
  );
  const captionAlignmentList = ["center", "left", "right", "custom"];
  const [isDisplayCaption, setIsDisplayCaption] = React.useState<boolean>(
    startElement.displayCaption ? startElement.displayCaption : false
  );

  const [captionOrder, setCaptionOrder] = React.useState<string>(
    startElement.captionOrder ? startElement.captionOrder : "below"
  );

  const [fontSize, setFontsize] = React.useState<string>(
    startElement.fontSize ? startElement.fontSize : "small"
  );
  const [color, setColor] = React.useState<string>(
    startElement.color ? startElement.color : "black"
  );
  const [style, setStyle] = React.useState<string>(
    startElement.style ? startElement.style : "normal"
  );
  const [font, setFont] = React.useState<string>(
    startElement.font ? startElement.font : "arial"
  );
  const [isNumberFormat, setIsNumberFormat] = React.useState<boolean>(
    startElement.isNumberFormat ? startElement.isNumberFormat : false
  );

  const [isBold, setIsBold] = React.useState<boolean>(
    startElement.isBold ? startElement.isBold : false
  );
  const [isItalic, setIsItalic] = React.useState<boolean>(
    startElement.isItalic ? startElement.isItalic : false
  );
  const [isUnderline, setIsUnderline] = React.useState<boolean>(
    startElement.isUnderline ? startElement.isUnderline : false
  );

  const [isStandard, setIsStandard] = React.useState<boolean>(
    startElement.isStandard ? startElement.isStandard : false
  );
  const [selectedStyles, setSelectedStyles] = useState<string[]>(() => {
    const initialSelectedStyles: string[] = [];
    if (isStandard) {
      initialSelectedStyles.push("standard");
    }
    if (isBold) {
      initialSelectedStyles.push("bold");
    }
    if (isItalic) {
      initialSelectedStyles.push("italic");
    }
    if (isUnderline) {
      initialSelectedStyles.push("underline");
    }
    return initialSelectedStyles;
  });

  const [customTableDetails, setCustomTableDetails] = React.useState(
    props.currentThemeDetails
      ? { ...props.currentThemeDetails, rows: rows, columns: columns }
      : TABLE_THEMES.default
  );

  const desiredFont = React.useMemo(() => {
    return findMatchingFont(font);
  }, [font]);

  let countTableElement = 0;
  const documentTemplate = props.documentTemplate as DocumentTemplate;
  let exitLoop = false; // Flag variable to track if we need to exit from all loops
  // Labeled statement for the outermost loop
  outerLoop: if (documentTemplate.sections) {
    for (const section of documentTemplate.sections) {
      if (section.subsections) {
        for (const subsection of section.subsections) {
          if (
            Array.isArray(subsection.elements) && // Check if elements is an array
            subsection.elements.length > 0 &&
            subsection.elements[0]?.content_type === "TABLE" // Added optional chaining
          ) {
            if (subsection.elements[0].id === props.element?.id) {
              countTableElement++;
              exitLoop = true; // Set the flag to true if condition is met
              break outerLoop; // Break out of all loops using the labeled statement
            } else if (
              JSON.parse(subsection.elements[0].content_format)
                .isNumberFormat === true
              // && JSON.parse(subsection.elements[0].content_format).isDisplayCaption==="true"
            ) {
              countTableElement++;
            }
          }
        }
      }
    }
  }
  //Functions
  const handleOpenThemesDialog = () => {
    setThemesDialogOpen(true);
  };
  const handleCloseThemesDialog = () => {
    setThemesDialogOpen(false);
  };

  // To Update the Setting Table

  const handleSaveThemeProperties = (updatedProperties: any) => {
    setUpdateTheme(true);
    setCustomTableDetails({
      ...updatedProperties,
      rows: rows,
      columns: columns,
      leftMargin: customTableDetails.leftMargin,
      rightMargin: customTableDetails.rightMargin,
      colType: customTableDetails.colType,
      rowType: customTableDetails.rowType,
    });
  };

  const handleUpdateProperties = (updatedProperties: any) => {
    setUpdateTheme(true);
    setCustomTableDetails({
      ...updatedProperties,
      rows: rows,
      columns: columns,
    });
  };

  const handleSetParameters = () => {
    // Update the current Theme to the canvas element.
    if (props.fromSettings) {
      props.setIsEditConfigOpen(false);
      setTimeout(() => {
        props.setIsEditConfigOpen(true);
      }, 100);
    }

    props.updateCurrentThemeDetails(customTableDetails);
    const updatedTableData = createUpdateTable(
      props.originalTable,
      rows,
      columns
    );
    //Generate Updated Table
    const newTableArray: Array<string> = [];

    updatedTableData.forEach((_: any) => {
      newTableArray.push(_.join("!TC"));
    });
    const newTableString = newTableArray.join("!TR");
    const isContentChanged =
      props.originalTable.length !== updatedTableData.length ||
      props.originalTable[0].length !== updatedTableData[0].length;

    const contentFormat = JSON.stringify({
      format: "MARKDOWN",
      columns: columns,
      rows: rows,
      caption: caption,
      isDynamicTable: startElement.isDynamicTable,
      dynamicVariable: startElement.dynamicVariable,
      justify: customTableDetails.tableAlignmnet,
      // justify:       justify,
      justifyContent: justifyContent,
      displayCaption: isDisplayCaption,
      captionAlignment: captionAlignment,
      captionOrder: captionOrder,
      font: font,
      fontSize: fontSize,
      color: color,
      isNumberFormat: isDisplayCaption ? isNumberFormat : false,
      isBold: isBold,
      isItalic: isItalic,
      isUnderline: isUnderline,
      isStandard: isStandard,
      //CellBorderThickness,
      tableFont: customTableDetails.tableFont,
      tableBorderStyle: customTableDetails.CellBorderStyle,
      tableBorderColor: customTableDetails.CellBorderColor,
      tableBorderThickness: customTableDetails.CellBorderThickness,
      /// table properties
      tableSize: customTableDetails.tableSize,
      columnSize: customTableDetails.columnSize, // cm
      rowWidth: props.rowSize,
      rowSize: props.rowSize,
      tableWidth:
        customTableDetails.tableSize === "auto"
          ? "0"
          : customTableDetails.tableWidth,
      tableAlignment: customTableDetails.tableAlignmnet,
      rightMargin: customTableDetails.rightMargin
        ? parseInt(customTableDetails.rightMargin, 10)
        : 0,
      leftMargin: customTableDetails.leftMargin
        ? parseInt(customTableDetails.leftMargin, 10)
        : 0,
      colType: props.colType ? props.colType : "automatic",
      rowType: props.rowType ? props.rowType : "automatic",
      themeDetails: customTableDetails,
      captionIndent: captionIndent,
    });

    // const formattedTableData = formatTableWithTheme(
    //   rows,
    //   columns,
    //   customTableDetails
    // );
    const formattedTableData = updateTableThemeFormatting(
      rows,
      columns,
      customTableDetails,
      props.element?.formatting != null
        ? JSON.parse(props.element.formatting)
        : JSON.parse(DEFAULT_THEME_FORMATTING),
      props.fromThemes
    );

    const stringFormat = JSON.stringify(formattedTableData);

    if (isContentChanged) {
      actions.updateDocumentElementContent({
        section: props.section!,
        subSection: props.subSection,
        documentElement: {
          ...props.element,
          formatting:
            updateTheme ||
            startElement.columns != columns ||
            startElement.rows != rows
              ? stringFormat
              : props.element.formatting,
          content: newTableString,
          content_format: contentFormat,
        },
      });
    } else {
      actions.updateDocumentElementContent({
        section: props.section!,
        subSection: props.subSection,
        documentElement: {
          ...props.element,
          formatting:
            updateTheme ||
            startElement.columns != columns ||
            startElement.rows != rows
              ? stringFormat
              : props.element.formatting,
          content: newTableString,
          content_format: contentFormat,
        },
      });
    }

    props.setRows(rows);
    props.setColumns(columns);

    props.setOriginalTable(getTableArray(newTableString, rows, columns));

    if (props.sendData) {
      props.sendData({
        rows,
        columns,
        caption,
        captionAlignment,
        isDisplayCaption,
        captionOrder,
        fontSize,
        font,
        style,
        color,
        isNumberFormat,
        isBold,
        isStandard,
        isUnderline,
        isItalic,
      });
    }
    props.closeDialogHandler();
  };

  const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    switch (name) {
      case "bold":
        setIsBold(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles: any) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style: any) => style !== name)
        );
        break;
      case "italic":
        setIsItalic(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles: any) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style: any) => style !== name)
        );
        break;
      case "underline":
        setIsUnderline(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles: any) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style: any) => style !== name)
        );
        break;
      case "standard":
        setIsStandard(checked);
        setIsBold(false);
        setIsItalic(false);
        setIsUnderline(false);
        setSelectedStyles(checked ? ["standard"] : []);
        break;
      default:
        break;
    }
  };

  const handleRowsChange = (e: any) => {
    const inputValue = e.target.value;
    const newValue = inputValue.replace(/\D/g, ""); // Remove non-digit characters
    setRows(parseInt(newValue));
    setCustomTableDetails({ ...customTableDetails, rows: parseInt(newValue) });
  };

  const handleColumnsChange = (e: any) => {
    parseInt;
    const inputValue = e.target.value;
    const newValue = inputValue.replace(/\D/g, ""); // Remove non-digit characters
    setColumns(parseInt(newValue));
    setCustomTableDetails({
      ...customTableDetails,
      columns: parseInt(newValue),
    });
  };

  const TableCaption = () => {
    return (
      <Box
        sx={{
          //  textAlign: captionAlignment,
          textAlign: captionAlignment === "custom" ? "left" : captionAlignment,
          padding: "5px",
          paddingLeft:
            captionAlignment === "custom"
              ? convertMmToPt(captionIndent) + "pt"
              : "5px",
          paddingTop: "3px",
        }}
      >
        <Typography
          sx={{
            fontFamily: desiredFont,
            fontSize: getFontSize(fontSize),

            fontStyle: isItalic ? "italic" : "normal",
            fontWeight: isBold ? "bold" : "normal",
            textDecoration: isUnderline ? "underline" : "none",
            color: "#" + color,
          }}
        >
          {isNumberFormat && isDisplayCaption
            ? "Table " + countTableElement + ": " + caption
            : caption}
        </Typography>
      </Box>
    );
  };

  //Settings Tabs
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={(e) => props.closeDialogHandler()}
        fullWidth
        PaperProps={{
          sx: {
            height: "80%",
          },
        }}
        maxWidth="md" // Set maxWidth to adjust the width of the dialog
      >
        {}
        <DialogTitle>
          <Typography variant="h5" flexGrow={1}>
            {"Table Settings"}
          </Typography>
        </DialogTitle>
        {/* //view theme */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {props.fromThemes ? (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Themes" {...a11yProps(0)} />
            </Tabs>
          ) : (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Formatting" {...a11yProps(1)} />
              <Tab label="Captions" {...a11yProps(2)} />
            </Tabs>
          )}
        </Box>
        <DialogContent>
          {props.fromThemes ? (
            <CustomTabPanel value={value} index={0}>
              <Box
                sx={{
                  alignContent: "center",
                  textAlign: "center",
                  paddingTop: 2,
                }}
              >
                {captionOrder === "above" && isDisplayCaption && TableCaption()}
                {customTableDetails != null && (
                  <TableElementTheme
                    themeStyle={"custom"}
                    themeCode={""}
                    customTableTheme={customTableDetails}
                  />
                )}

                {captionOrder === "below" && isDisplayCaption && TableCaption()}
                <Button
                  sx={{ my: 1 }}
                  variant="outlined"
                  onClick={handleOpenThemesDialog}
                >
                  View Themes
                </Button>
                <Divider></Divider>
              </Box>
            </CustomTabPanel>
          ) : (
            <>
              {!props.fromThemes && value === 0 && (
                <>
                  <Box display={"flex"} width={"100%"} marginBottom={2}>
                    {
                      <Grid item xs={6} sx={{}}>
                        <Box sx={{ marginTop: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="table-columns">
                              Columns
                            </InputLabel>
                            <Input
                              value={Number.isNaN(columns) ? " " : columns}
                              onChange={handleColumnsChange}
                              id="table-colums"
                              inputProps={{
                                pattern: "[1-9][0-9]*",
                                title:
                                  "Please enter a positive number greater than 0",
                              }}
                            />
                            {columns > 7 && (
                              <Alert severity="warning">
                                The maximum allowed columns are 7. Exceeding
                                this limit may cause alignmnet issue.
                              </Alert>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    }
                    <Box marginRight={5} />
                    {
                      <Grid item xs={6}>
                        <Box sx={{ marginTop: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="table-rows">Rows</InputLabel>
                            <Input
                              value={Number.isNaN(rows) ? " " : rows}
                              onChange={handleRowsChange}
                              id="table-rows"
                              inputProps={{
                                pattern: "[1-9][0-9]*",
                                title:
                                  "Please enter a positive number greater than 0",
                              }}
                            />
                            {rows > 64 && (
                              <Alert severity="warning">
                                The maximum allowed rows are 64. Exceeding this
                                limit may cause alignmnet issue.
                              </Alert>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    }
                  </Box>
                  <Box
                    sx={{
                      alignContent: "center",
                      width: "100%",
                    }}
                  >
                    <CustomTableDialog
                      open={true}
                      onClose={handleCloseThemesDialog}
                      onUpdate={handleUpdateProperties}
                      currentDetails={customTableDetails}
                    />
                  </Box>
                </>
              )}
              {value === 1 && (
                <Grid item xs={12} marginBottom={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isDisplayCaption}
                        onChange={(e) => setIsDisplayCaption(e.target.checked)}
                      />
                    }
                    label="Display Caption"
                  />

                  {isDisplayCaption && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isNumberFormat}
                          onChange={(e) => setIsNumberFormat(e.target.checked)}
                        />
                      }
                      label="Allow Number Format"
                    />
                  )}
                </Grid>
              )}
              {isDisplayCaption && value === 1 && (
                <>
                  <Grid item xs={12}>
                    <Box sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        label="Table Caption"
                        value={caption}
                        onChange={(e) => setCaption(e.target.value)}
                        variant="outlined"
                        InputProps={{
                          startAdornment: isNumberFormat && (
                            <InputAdornment position="start">
                              Table {countTableElement}:
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        label="Placement"
                        value={captionOrder}
                        onChange={(e) => setCaptionOrder(e.target.value)}
                        variant="outlined"
                      >
                        <MenuItem value="below">Below</MenuItem>
                        <MenuItem value="above">Above</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={captionAlignment === "custom" ? 6 : 12}
                          sm={captionAlignment === "custom" ? 6 : 12}
                        >
                          <TextField
                            select
                            fullWidth
                            label="Alignment"
                            value={captionAlignment}
                            onChange={(e) =>
                              setCaptionAlignment(e.target.value)
                            }
                            variant="outlined"
                          >
                            <MenuItem value="center">Center</MenuItem>
                            <MenuItem value="left">Left</MenuItem>
                            <MenuItem value="right">Right</MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                          </TextField>
                        </Grid>

                        {captionAlignment === "custom" && (
                          <Grid item xs={6} sm={6}>
                            <TextField
                              fullWidth
                              type="number"
                              label="Caption Indent"
                              value={captionIndent}
                              onChange={(e) => {
                                if (Number(e.target.value) >= 0) {
                                  setCaptionIndent(Number(e.target.value));
                                }
                              }}
                              variant="outlined"
                              InputProps={{
                                endAdornment: [
                                  <InputAdornment key="start" position="end">
                                    mm
                                  </InputAdornment>,
                                ],
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        value={font_Families.find(
                          (option) => option.value === font
                        )}
                        disablePortal
                        options={font_Families}
                        onChange={(event: any, newValue: any) => {
                          const selectedValue = newValue
                            ? newValue.value
                            : null;
                          setFont(selectedValue);
                        }}
                        renderInput={(params: any) => (
                          <TextField {...params} label="Font" />
                        )}
                        disableClearable
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            style={{
                              fontFamily: findMatchingFont(option.value),
                            }}
                          >
                            {option.label}
                          </li>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ColorPicker
                        label="Color"
                        color={color}
                        onChange={(newColor) => setColor(newColor)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="style-select">Style</InputLabel>
                        <Select
                          labelId="style-label"
                          id="style-select"
                          label="Style"
                          variant="outlined"
                          multiple
                          value={selectedStyles}
                          renderValue={(selected) =>
                            (selected as string[]).join(", ")
                          }
                        >
                          <MenuItem sx={{ padding: 0, margin: 0 }}>
                            <FormControlLabel
                              sx={{ padding: 0, margin: 0 }}
                              control={
                                <Checkbox
                                  checked={isStandard}
                                  name="standard"
                                  onChange={handleStyleChange}
                                />
                              }
                              label="Standard"
                            />
                          </MenuItem>
                          <MenuItem sx={{ padding: 0, margin: 0 }}>
                            <FormControlLabel
                              sx={{ padding: 0, margin: 0 }}
                              control={
                                <Checkbox
                                  checked={isBold}
                                  name="bold"
                                  onChange={handleStyleChange}
                                />
                              }
                              label="Bold"
                            />
                          </MenuItem>
                          <MenuItem sx={{ padding: 0, margin: 0 }}>
                            <FormControlLabel
                              sx={{ padding: 0, margin: 0 }}
                              control={
                                <Checkbox
                                  checked={isItalic}
                                  name="italic"
                                  onChange={handleStyleChange}
                                />
                              }
                              label="Italic"
                            />
                          </MenuItem>
                          <MenuItem sx={{ padding: 0, margin: 0 }}>
                            <FormControlLabel
                              sx={{ padding: 0, margin: 0 }}
                              control={
                                <Checkbox
                                  checked={isUnderline}
                                  name="underline"
                                  onChange={handleStyleChange}
                                />
                              }
                              label="Underline"
                            />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="image-size">Font Size</InputLabel>
                        <Select
                          value={fontSize}
                          onChange={(e) => setFontsize(e.target.value)}
                          fullWidth
                          labelId="FontSize"
                          label="FontSize"
                        >
                          <MenuItem value="tiny">6pt</MenuItem>
                          <MenuItem value="scriptsize">7pt</MenuItem>
                          <MenuItem value="footnotesize">8pt</MenuItem>
                          <MenuItem value="small">9pt</MenuItem>
                          <MenuItem value="normalsize">10pt</MenuItem>
                          <MenuItem value="large">12pt</MenuItem>
                          <MenuItem value="Large">14pt</MenuItem>
                          <MenuItem value="LARGE">16pt</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </DialogContent>
        {/* //cancel and save buttons */}
        <DialogActions>
          <Button
            variant="outlined"
            onClick={(e) => props.closeDialogHandler()}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSetParameters}
            disabled={!rows || rows === 0 || !columns || columns === 0}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Default themes Dialog Component */}
      <ThemeDialog
        open={themesDialogOpen}
        onClose={handleCloseThemesDialog}
        onUpdate={handleSaveThemeProperties}
        // currentDetails={customTableDetails}
      />
    </>
  );
}
